import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";
import Home2Page from "../home/index2";
import Home3Page from "../home/index3";

function CandidateDetail1Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "교통 및 생활 편의시설",
            title: "생활",
            image: "images/candidates/study5.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutText = [
            {
                title:"1) 대중교통 이용",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: "지하철",
                        item_discription: (
                            <>
                                <p>서울과 대전, 부산 등 대도시에는 지하철 노선이 잘 갖춰져 있습니다. 환승이 편리하고 지하철역이 도시 곳곳에 위치해 접근성이 좋습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "버스",
                        item_discription: (
                            <>
                                <p>노선이 광범위하게 운영되고 있어 지하철이 가지 않는 지역도 버스로 이동할 수 있습니다. 광역버스, 좌석버스, 마을버스 등 다양한 종류가 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "택시",
                        item_discription: (
                            <>
                                <p>전국 어디서나 부를 수 있고 요금이 저렴한 편입니다. 모바일앱으로도 편리하게 예약할 수 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: "images/candidates/study5_1.png",
                        item_title: "교통카드",
                        item_discription: (
                            <>
                                <p>버스와 지하철에서 교통카드를 사용하면 현금 없이도 이용할 수 있습니다. 환승 할인도 제공됩니다.</p>
                                <p>교통카드는 지하철역과 24시 편의점에서 쉽게 구매가 가능하며, 외국인의 경우 일반적으로 선불제 카드를 구매하여, 미리 요금을 충전한 다음, 사용할 때마다 차감해 나가는 방식이 일반적입니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            },
            {
                title:"2) 은행, 우체국 등 생활 인프라",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: "images/candidates/study5_2.png",
                        item_title: "은행",
                        item_discription: (
                            <>
                                <p>주요 시중은행으로는 KB국민은행, 신한은행, 우리은행, 하나은행 등이 있습니다.</p>
                                <p>은행 영업시간은 평일 오전 9시부터 오후 4시까지입니다.</p>
                                <p>계좌 개설, 환전, 송금 등 기본 서비스 외에도 적금, 펀드, 대출 등 다양한 상품을 취급합니다.</p>
                                <p>인터넷/모바일 뱅킹 서비스도 잘 발달되어 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: "images/candidates/study5_3.png",
                        item_title: "우체국",
                        item_discription: (
                            <>
                                <p>전국에 3,500여 개소가 운영 중입니다.</p>
                                <p>우편물 발송 외에 은행 업무, 보험 업무도 함께 제공합니다.</p>
                                <p>소액 해외송금도 가능하며, 운영시간은 평일 오전 9시부터 오후 6시까지입니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: "images/candidates/study5_4.png",
                        item_title: "경찰서",
                        item_discription: (
                            <>
                                <p>전국에 약 600여개의 경찰서가 있습니다.</p>
                                <p>범죄 신고, 수사, 치안 유지 등의 업무를 담당합니다.</p>
                                <p>112에 전화하면 가장 가까운 경찰서로 연결됩니다.</p>
                                <p>외국인을 위한 통역서비스와 고충상담 창구도 마련되어 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: "images/candidates/study5_6.png",
                        item_title: "주민센터",
                        item_discription: (
                            <>
                                <p>각 구(區)와 동(洞) 단위로 주민센터가 있습니다.</p>
                                <p>주민등록, 인감 발급, 제증명 발급 등 주민 행정서비스를 제공합니다.</p>
                                <p>복지, 세무, 취업 등 다양한 상담 창구가 마련되어 있습니다.</p>
                                <p>외국인들도 외국인등록, 체류기간 연장 등의 업무를 볼 수 있습니다.</p>
                                <p>통역 서비스를 지원하므로 언어가 불편하더라도 이용할 수 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: "images/candidates/study5_7.png",
                        item_title: "도서관",
                        item_discription: (
                            <>
                                <p>전국에 공공도서관과 대학도서관 등 다양한 도서관이 있습니다.</p>
                                <p>책 대출뿐만 아니라 열람실, 문화프로그램, 독서모임 등 다양한 서비스를 제공합니다.</p>
                                <p>대형 도서관에는 외국어 도서와 외국인 전용 열람실도 갖추고 있습니다.</p>
                                <p>디지털도서관에서는 전자책, 오디오북 등을 대출할 수 있습니다.</p>
                                <p>회원가입 방법과 운영시간 등은 도서관마다 조금씩 다를 수 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            },
            {
                title:"3) 쇼핑 및 여가생활",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: "images/candidates/study5_8.png",
                        item_title: "쇼핑",
                        item_discription: (
                            <>
                                <p>한국에는 규모가 큰 대형 쇼핑몰부터 재래시장, 상점가 등 다양한 쇼핑 장소가 있습니다.</p>
                                <p>서울에는 명동, 동대문, 이태원 등 유명 쇼핑 거리가 많습니다.</p>
                                <p>대형마트와 백화점에서는 식료품, 의류, 가전제품 등을 한번에 구매할 수 있습니다.</p>
                                <p>전통시장에서는 신선한 식재료와 값싼 생활용품을 살 수 있습니다.</p>
                                <p>한국 화장품, 의류, 전자제품 등은 품질 좋고 가격도 합리적입니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: "images/candidates/study5_9.png",
                        item_title: "여가생활",
                        item_discription: (
                            <>
                                <p>한국인들은 영화관람, 노래방, PC방, 보드게임 등 여가활동을 좋아합니다.</p>
                                <p>서울과 대도시에는 대형 복합쇼핑몰이 많아 쇼핑과 영화, 식사를 한번에 즐길 수 있습니다.</p>
                                <p>한국의 전통문화체험도 해볼 만합니다. 전통음식 만들기, 한복입기, 사물놀이 등입니다.</p>
                                <p>산과 공원이 도시 곳곳에 있어 등산, 조깅, 피크닉 등 야외활동도 가능합니다.</p>
                                <p>한류 영향으로 K-pop 공연, 뮤지컬 등 공연문화도 발달했습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            },
        ]

        const workExperienceItems = [
            {
                title: "지도 사이드",
                site:[
                    {
                        siteName : "T맵",
                        contactInfo: "https://www.tmapmobility.com"
                    },
                ]
            },
        ];

        return (
            <>
                <div className="section-full p-b90 bg-white">
                <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {/*items*/}
                                            {aboutText.map((item, index) => (
                                                item && (
                                                    <div key={index}>
                                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{item.title}</h4>
                                                        {item.img && <JobZImage src={item.img} alt="#" />}
                                                        <div>
                                                            {item.discription}
                                                        </div>
                                                        <ul className="description-list-2">
                                                            {item.Static_items && item.Static_items.map((innerItem, innerIndex) => (
                                                                innerItem && (
                                                                    <li key={innerIndex}>
                                                                        <i className="feather-check" />
                                                                        <span style={{fontWeight:"bold"}}>{innerItem.item_title}</span>
                                                                        <div>
                                                                        {innerItem.img && <JobZImage src={innerItem.img} alt="#" />}
                                                                        </div>
                                                                        <div>
                                                                            {innerItem.item_discription}
                                                                        </div>
                                                                        <ul className="description-list-2">
                                                                            {innerItem.item_inner.map((subItem, subIndex) => (
                                                                                <li key={subIndex}>
                                                                                    {subItem}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            ))}
                                        {/*items*/}
                                    </div>
                                    
                                    <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 웹사이트</h4>
                                    <div className="twm-timing-list-wrap">
                                        {workExperienceItems && workExperienceItems.map((item, index) => (
                                        <div className="twm-timing-list" key={index}>
                                            <div className="twm-time-list-title">{item.title}</div>
                                            {item.site.map((siteItem, siteIndex) => (
                                                <div key={siteIndex}>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {siteItem.contactInfo && <NavLink to={siteItem.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">{siteItem.siteName} 바로가기</NavLink>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <Home2Page />
                            <Home3Page />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateDetail1Page;
