import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import SectionBlogsSidebar from "../../sections/blogs/sidebar/section-blogs-sidebar";

function BlogDetail8Page() {
    // 블로그 데이터 변수 정의
    const blogData = {
        title: "선물",
        imageSrc: "images/blog/latest/bg8.png",
        description: [
            "한국의 선물 문화는 상호간의 감정표현과 관계 강화를 위해 중요한 역할을 합니다. 선물은 주로 생일, 결혼, 출산, 입학, 새 집들이 등 다양한 기념일이나 행사 때 주고 받으며, 선물을 주는 것은 상대방에 대한 예의와 감사의 표현으로 여겨집니다.",
            "한국인들은 선물을 선택할 때에도 신중하게 고민하며, 상대방의 취향과 성향을 고려하여 선물을 준비합니다. 또한, 선물 포장에도 신경을 쓰며 예쁜 포장지나 리본 등으로 선물을 꾸며주는 것이 일반적입니다.",
            "선물을 받았을 때에는 곧장 열지 않고, 예의 바르게 받아들이는 것이 중요합니다. 또한, 선물을 받았을 때에는 감사의 인사를 꼭 전하는 것이 예절입니다. 선물을 주고 받는 것은 서로간의 관계를 더욱 깊게 만들어주는 소중한 시간입니다."
        ],
        aboutBusiness: [
            {
                title: "선물 선택의 중요성",
                content: "한국의 선물 문화에서는 선물을 선택할 때에도 신중한 고민이 필요합니다. 상대방의 취향과 성향을 고려하여 선물을 선정하는 것이 중요합니다."
            },
            {
                title: "선물 포장의 예쁘게",
                content: "선물을 주거나 받을 때에는 예쁜 포장지나 리본 등으로 선물을 꾸며주는 것이 일반적입니다. 선물 포장에도 신경을 쓰는 것이 선물을 주는 마음을 더욱 잘 전달할 수 있습니다."
            },
            {
                title: "선물 받았을 때의 예절",
                content: "선물을 받았을 때에는 곧장 열지 않고, 예의 바르게 받아들이는 것이 중요합니다. 또한, 선물을 받았을 때에는 감사의 인사를 꼭 전하는 것이 예절입니다."
            }
        ],
        listItems: [
            "한국의 선물 문화는 상호간의 감정표현과 관계 강화를 위해 중요한 역할을 합니다.",
            "선물을 선택할 때에는 상대방의 취향과 성향을 고려하여 신중하게 고민해야 합니다.",
            "선물을 받았을 때에는 곧장 열지 않고, 예의 바르게 받아들이며 감사의 인사를 전하는 것이 중요합니다."
        ]
    };
    
    const blockquoteData = [
        {
            author: "수진",
            quote: "오늘 생일 선물로 뭐가 좋을까 고민 중이야."
        },
        {
            author: "민호",
            quote: "선물을 받았을 때는 항상 감사의 마음을 전해야 해."
        }
    ];
    
    function CustomBlockQuote({ quote, author }) {
        return (
            <blockquote>
                <strong style={{fontWeight:"bold"}}>{author}</strong>
                <p><span>"</span>- {quote}</p>
            </blockquote>
        );
    }

    useEffect(() => {
        loadScript("js/custom.js");
    });

    return (
        <>
            <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    {/* BLOG SECTION START */}
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                {/* BLOG START */}
                                <div className="blog-post-single-outer">
                                    <div className="blog-post-single bg-white">
                                        <div className="wt-post-info">
                                            <div className="wt-post-media m-b30" style={{width: "100%", display:"flex", justifyContent:"center"}}>
                                                <JobZImage src={blogData.imageSrc} alt="" style={{width: "300px"}}/>
                                            </div>
                                            <div className="wt-post-title">
                                                <h3 className="post-title">{blogData.title}</h3>
                                            </div>
                                            <div className="wt-post-discription">
                                                {blogData.description.map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                                <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{blogData.aboutBusiness.title}</h4>
                                                {blogData.aboutBusiness.map((about, index) => (
                                                    <div key={index}>
                                                        <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{about.title}</h4>
                                                        <p>{about.content}</p>
                                                    </div>
                                                ))}
                                                <div className="wt-post-discription">
                                                    {blockquoteData.map((data, index) => (
                                                        <CustomBlockQuote key={index} quote={data.quote} author={data.author} />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="post-single-list">
                                                <ul className="description-list-2">
                                                    {blogData.listItems.map((item, index) => (
                                                        <li key={index}>
                                                            <i className="feather-check" />
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-navigation m-t30">
                                        <div className="post-nav-links" style={{ display: "block" }}>
                                            <NavLink to={publicUser.blog.GRID1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="post-nav-item nav-post-prev" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="nav-post-arrow" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />이전 페이지
                                                </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogDetail8Page;
