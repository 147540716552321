import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import SectionBlogsSidebar from "../../sections/blogs/sidebar/section-blogs-sidebar";

function BlogDetail4Page() {
    // 블로그 데이터 변수 정의
    const blogData = {
        title: "술",
        imageSrc: "images/blog/latest/bg4.png",
        description: [
            "한국은 음주 문화가 두드러지는 나라로, 술 자리는 사람들 간의 소통과 친목을 다지는 장소로 여겨집니다. 한국인들은 술자리에서 서로의 감정을 솔직하게 털어놓고 스트레스를 해소하는 등 다양한 목적으로 음주를 즐깁니다.",
            "하지만 한국의 음주 문화에서는 술 마실 때 예절을 지키는 것이 중요합니다. 가장 기본적인 예절은 술 잔을 받은 손으로 들이거나 음료를 따르는 것입니다. 또한, 술을 따르거나 건배할 때에는 상대방을 향해 공손하고 존중하는 태도를 유지해야 합니다.",
            "또한, 술 자리에서는 상대방의 음주량을 적절히 파악하고 강요하지 않는 것이 중요합니다. 상대방이 음주를 거부한다면 존중해야 하며, 음주 후에는 교통사고 예방을 위해 안전하게 집으로 돌아갈 수 있도록 도와주어야 합니다."
        ],
        aboutBusiness: [
            {
                title: "음주 문화의 다양한 목적",
                content: "한국의 음주 문화는 사람들 간의 소통과 친목을 다지는 장소로 여겨집니다. 술자리에서는 서로의 감정을 솔직하게 털어놓고 스트레스를 해소하는 등 다양한 목적으로 음주를 즐깁니다."
            },
            {
                title: "술 마실 때의 예절",
                content: "한국의 음주 문화에서는 술 마실 때 예절을 지키는 것이 중요합니다. 상대방을 공손하고 존중하는 태도로 대접하고, 술을 따르거나 건배할 때에도 예의를 갖추어야 합니다."
            },
            {
                title: "상대방의 음주량 존중",
                content: "술 자리에서는 상대방의 음주량을 적절히 파악하고 강요하지 않아야 합니다. 음주를 거부하는 경우에는 존중하고, 음주 후에는 안전하게 집으로 돌아갈 수 있도록 도와주어야 합니다."
            }
        ],
        listItems: [
            "한국의 음주 문화는 사람들 간의 소통과 친목을 다지는 장소로 여겨집니다.",
            "술자리에서는 상대방을 공손하고 존중하는 태도로 대접하는 것이 중요합니다.",
            "상대방의 음주량을 적절히 파악하고 강요하지 않는 것이 술 마실 때의 예절입니다."
        ]
    };
    
    const blockquoteData = [
        {
            author: "민수",
            quote: "오늘은 술 한잔하며 편하게 이야기해보자."
        },
        {
            author: "지영",
            quote: "술자리에서는 서로의 음주량을 존중해야 해."
        }
    ];

    function CustomBlockQuote({ quote, author }) {
        return (
            <blockquote>
                <strong style={{fontWeight:"bold"}}>{author}</strong>
                <p><span>"</span>- {quote}</p>
            </blockquote>
        );
    }

    useEffect(() => {
        loadScript("js/custom.js");
    });

    return (
        <>
            <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    {/* BLOG SECTION START */}
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                {/* BLOG START */}
                                <div className="blog-post-single-outer">
                                    <div className="blog-post-single bg-white">
                                        <div className="wt-post-info">
                                            <div className="wt-post-media m-b30" style={{width: "100%", display:"flex", justifyContent:"center"}}>
                                                <JobZImage src={blogData.imageSrc} alt="" style={{width: "300px"}}/>
                                            </div>
                                            <div className="wt-post-title">
                                                <h3 className="post-title">{blogData.title}</h3>
                                            </div>
                                            <div className="wt-post-discription">
                                                {blogData.description.map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                                <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{blogData.aboutBusiness.title}</h4>
                                                {blogData.aboutBusiness.map((about, index) => (
                                                    <div key={index}>
                                                        <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{about.title}</h4>
                                                        <p>{about.content}</p>
                                                    </div>
                                                ))}
                                                <div className="wt-post-discription">
                                                    {blockquoteData.map((data, index) => (
                                                        <CustomBlockQuote key={index} quote={data.quote} author={data.author} />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="post-single-list">
                                                <ul className="description-list-2">
                                                    {blogData.listItems.map((item, index) => (
                                                        <li key={index}>
                                                            <i className="feather-check" />
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-navigation m-t30">
                                        <div className="post-nav-links" style={{ display: "block" }}>
                                            <NavLink to={publicUser.blog.GRID1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="post-nav-item nav-post-prev" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="nav-post-arrow" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />이전 페이지
                                                </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogDetail4Page;
