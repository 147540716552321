import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";
import Home2Page from "../home/index2";
import Home3Page from "../home/index3";

function CandidateDetail1Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "취업",
            title: "일자리",
            image: "images/candidates/study10.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutText = [
            {
                title:"1) 취업 비자 및 고용 가능 자격 안내",
                img: "images/candidates/study10_1.png",
                discription: null,
                Static_items:[null]
            },
            {
                title:"2) 채용 정보 사이트/온라인 구직 활동",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: "워크비자",
                        item_discription: null,
                        item_inner: [
                            (
                                <>
                                    <p>외국인 매칭 전용</p>
                                </>
                            )
                        ],
                    },
                    {
                        img: null,
                        item_title: "잡코리아",
                        item_discription: null,
                        item_inner: [
                            (
                                <>
                                    <p>한국인 전용</p>
                                </>
                            )
                        ],
                    },
                    {
                        img: null,
                        item_title: "사람인",
                        item_discription: null,
                        item_inner: [
                            (
                                <>
                                    <p>한국인 전용</p>
                                </>
                            )
                        ],
                    },
                ]
            },
            {
                title:"3) 이력서/자기소개서 작성법",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: "이력서 작성법",
                        item_discription: (
                            <>
                                <p>한국식 이력서 형식을 따르세요. 성명, 생년월일, 연락처, 학력, 경력 등의 정보를 기재합니다.</p>
                                <p>한국어 능력을 적절히 표기하세요. 공인 한국어능력시험 점수가 있다면 기재하는 것이 좋습니다.</p>
                                <p>한국 기업에서 근무한 경험이 있다면 강조하세요.</p>
                                <p>경력이 부족하다면 교내 활동, 봉사활동, 프로젝트 등을 작성하여 보완하세요.</p>
                                <p>맞춤법, 띄어쓰기 등에 주의하여 한국어 실력을 보여주세요.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "자기소개서 작성법",
                        item_discription: (
                            <>
                                <p>지원 동기, 장단점, 입사 후 포부 등을 한국어로 간략하게 작성하세요.</p>
                                <p>한국 문화에 대한 이해도를 드러내는 것이 좋습니다.</p>
                                <p>구체적인 사례를 들어 자신의 능력과 자질을 설명하세요.</p>
                                <p>지원 기업의 가치관, 비전과 연계하여 자신의 적합성을 피력하세요.</p>
                                <p>한국어가 서툴더라도 양해를 구하고 진정성 있게 작성하세요.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            },
            {
                title:"4) 근로계약서 검토 및 근로조건 이해",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: "근로계약기간",
                        item_discription: (
                            <>
                                <p>계약기간(시작일, 종료일)을 확인하고 계약 만료 전 재계약 조건을 숙지합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "업무내용 및 범위",
                        item_discription: (
                            <>
                                <p>담당 업무, 직위, 직급 등이 구체적으로 명시되어 있는지 확인합니다.</p>
                                <p>업무 범위를 벗어난 지시는 거부할 수 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "근로시간 및 휴게시간",
                        item_discription: (
                            <>
                                <p>법정 근로시간(주 40시간 또는 52시간)을 초과하는지 확인합니다.</p>
                                <p>휴게시간이 보장되는지 확인합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "임금 및 수당",
                        item_discription: (
                            <>
                                <p>임금 지급일자, 지급방식, 급여 구성항목(기본급, 수당 등)을 확인합니다.</p>
                                <p>최저임금 이상인지 확인합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "복리후생",
                        item_discription: (
                            <>
                                <p>법정 4대 보험 가입 여부를 확인합니다.</p>
                                <p>유급휴가, 상여금, 경조사비 등의 복리후생을 확인합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "근로조건의 변경",
                        item_discription: (
                            <>
                                <p>근로조건 변경 시 서면 동의가 필요한지 확인합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "계약해지 및 퇴직금",
                        item_discription: (
                            <>
                                <p>계약 해지사유와 절차를 숙지합니다.</p>
                                <p>퇴직금 지급 조건을 확인합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            },
            {
                title:"5) 4대 보험 및 퇴직금 제도 설명",
                img: null,
                discription: (
                    <>
                        <p>고용주는 외국인 근로자에게 4대 보험(건강보험, 국민연금, 고용보험, 산재보험)을 의무적으로 가입해야 합니다.</p>
                        <p>보험료는 근로자와 사업주가 절반씩 부담합니다.</p>
                        <p>4대 보험에 가입되어 있는지 근로계약서에서 확인해야 합니다.</p>
                    </>
                ),
                Static_items:[
                    {
                        img: null,
                        item_title: "① 건강보험",
                        item_discription: (
                            <>
                                <p>병원 진료 시 본인부담금을 제외한 나머지 치료비를 보험에서 보장</p>
                                <p>외국인도 가입 의무가 있으며 고용주가 50%의 보험료를 납부해야 함</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "② 국민연금",
                        item_discription: (
                            <>
                                <p>노령, 장애, 사망 등의 경우에 연금을 지급하는 공적연금제도</p>
                                <p>외국인도 가입해야 하며, 가입기간에 따라 연금 수령 자격이 주어짐</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "③ 고용보험",
                        item_discription: (
                            <>
                                <p>실업 시 구직급여를 받을 수 있고, 사업장 이직 및 재취업 지원</p>
                                <p>외국인도 가입 의무가 있으며 고용주가 50%의 보험료를 납부</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "④ 산재보험",
                        item_discription: (
                            <>
                                <p>업무상 재해나 질병에 대해 치료비와 휴업급여 등을 보장</p>
                                <p>사업주가 전액 부담하므로 근로자 부담 없음</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "퇴직금 제도",
                        item_discription: (
                            <>
                                <p>1년 이상 근무한 근로자에게 퇴직금을 지급해야 합니다.</p>
                                <p>퇴직금 산정 방식은 계속근로기간 1년에 대해 30일분 이상의 평균임금을 지급해야 합니다.</p>
                                <p>단, 퇴직연금제도에 가입된 경우 퇴직연금으로 대체 가능합니다.</p>
                                <p>근로계약서에 퇴직금이나 퇴직연금 적용 여부를 꼭 확인해야 합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            },
        ]
        const workExperienceItems = [
            {
                title: "구인구직 상담 센터",
                site:[
                    {
                        siteName : "워크비자",
                        contactInfo: "https://startkorea.com"
                    },
                ]
            },
            {
                title: "이력서 작성 샘플",
                site:[
                    {
                        siteName : "이력서 양식",
                        contactInfo: "https://visatype.co.kr/resume"
                    },
                ]
            },
        ];

        return (
            <>
                <div className="section-full p-b90 bg-white">
                <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {/*items*/}
                                            {aboutText.map((item, index) => (
                                                item && (
                                                    <div key={index}>
                                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{item.title}</h4>
                                                        {item.img && <JobZImage src={item.img} alt="#" />}
                                                        <div>
                                                            {item.discription}
                                                        </div>
                                                        <ul className="description-list-2">
                                                            {item.Static_items && item.Static_items.map((innerItem, innerIndex) => (
                                                                innerItem && (
                                                                    <li key={innerIndex}>
                                                                        <i className="feather-check" />
                                                                        <span style={{fontWeight:"bold"}}>{innerItem.item_title}</span>
                                                                        <div>
                                                                        {innerItem.img && <JobZImage src={innerItem.img} alt="#" />}
                                                                        </div>
                                                                        <div>
                                                                            {innerItem.item_discription}
                                                                        </div>
                                                                        <ul className="description-list-2">
                                                                            {innerItem.item_inner.map((subItem, subIndex) => (
                                                                                <li key={subIndex}>
                                                                                    {subItem}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            ))}
                                        {/*items*/}
                                    </div>
                                    
                                    <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 웹사이트</h4>
                                    <div className="twm-timing-list-wrap">
                                        {workExperienceItems.map((item, index) => (
                                        <div className="twm-timing-list" key={index}>
                                            <div className="twm-time-list-title">{item.title}</div>
                                            {item.site.map((siteItem, siteIndex) => (
                                                <div key={siteIndex}>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {siteItem.contactInfo && <NavLink to={siteItem.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">{siteItem.siteName} 바로가기</NavLink>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <Home2Page />
                            <Home3Page />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateDetail1Page;
