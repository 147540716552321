import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../../globals/route-names";
import SectionSideAdvert from "./section-side-advert";

import SectionRecordsFilter from "../../../sections/common/section-records-filter";
function SectionJobsSidebar1() {
    const _filterConfig = {
        prefix: "Showing",
        type: "Candidates",
        total: "2,150",
        showRange: false,
        showingUpto: ""
    };

    return (
        <>
            <SectionSideAdvert />
            <SectionRecordsFilter _config={_filterConfig} />
        </>
    )
}

export default SectionJobsSidebar1;