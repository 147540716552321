import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import SectionBlogsSidebar from "../../sections/blogs/sidebar/section-blogs-sidebar";

function BlogDetail6Page() {
    // 블로그 데이터 변수 정의
    const blogData = {
        title: "연락",
        imageSrc: "images/blog/latest/bg6.png",
        description: [
            "한국의 연락 문화는 빠르고 즉각적인 소통을 중요시하는 특징을 가지고 있습니다. 한국인들은 연락 수단으로 전화나 문자를 자주 활용하며, 소통이 원활하고 신속하게 이루어지는 것을 선호합니다.",
            "전화나 문자를 할 때에는 상대방의 시간을 고려하고 존중하는 것이 예절입니다. 예를 들어, 새벽이나 늦은 시간에는 부적절한 시간이므로 피하고, 중요한 사항이 아닌 이상 귀찮은 연락을 자제해야 합니다. 또한, 전화를 받았을 때는 친절하고 상냥한 태도로 대화를 진행하는 것이 중요합니다.",
            "또한, 한국에서는 비즈니스 환경에서의 연락도 중요한 역할을 합니다. 비즈니스 관련 전화나 이메일을 할 때에는 정중하고 명확한 어조로 대화해야 하며, 불필요한 혼란을 피하기 위해 필요한 정보를 명확히 전달하는 것이 중요합니다."
        ],
        aboutBusiness: [
            {
                title: "시간과 존중",
                content: "한국의 연락 문화에서는 전화나 문자를 할 때 상대방의 시간을 고려하고 존중하는 것이 중요합니다. 새벽이나 늦은 시간에는 부적절한 시간이므로 피하는 것이 예의입니다."
            },
            {
                title: "친절한 대화",
                content: "전화를 받았을 때는 친절하고 상냥한 태도로 대화를 진행하는 것이 중요합니다. 상대방에게 편안함을 줄 수 있는 대화를 이어가는 것이 좋습니다."
            },
            {
                title: "비즈니스 연락의 예절",
                content: "비즈니스 환경에서의 연락은 정중하고 명확한 어조로 이루어져야 합니다. 필요한 정보를 명확히 전달하고 혼란을 피하기 위해 신중하게 대화하는 것이 중요합니다."
            }
        ],
        listItems: [
            "한국의 연락 문화는 빠르고 즉각적인 소통을 중요시합니다.",
            "전화나 문자를 할 때에는 상대방의 시간을 고려하고 존중하는 것이 예절입니다.",
            "비즈니스 환경에서의 연락은 정중하고 명확한 어조로 이루어져야 하며, 필요한 정보를 명확히 전달하는 것이 중요합니다."
        ]
    };
    
    const blockquoteData = [
        {
            author: "지원",
            quote: "전화할 때는 상대방의 시간을 고려하는 것이 중요해."
        },
        {
            author: "성민",
            quote: "비즈니스 연락에서는 정중하고 명확한 어조로 대화하는 것이 필요해."
        }
    ];

    function CustomBlockQuote({ quote, author }) {
        return (
            <blockquote>
                <strong style={{fontWeight:"bold"}}>{author}</strong>
                <p><span>"</span>- {quote}</p>
            </blockquote>
        );
    }

    useEffect(() => {
        loadScript("js/custom.js");
    });

    return (
        <>
            <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    {/* BLOG SECTION START */}
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                {/* BLOG START */}
                                <div className="blog-post-single-outer">
                                    <div className="blog-post-single bg-white">
                                        <div className="wt-post-info">
                                            <div className="wt-post-media m-b30" style={{width: "100%", display:"flex", justifyContent:"center"}}>
                                                <JobZImage src={blogData.imageSrc} alt="" style={{width: "300px"}}/>
                                            </div>
                                            <div className="wt-post-title">
                                                <h3 className="post-title">{blogData.title}</h3>
                                            </div>
                                            <div className="wt-post-discription">
                                                {blogData.description.map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                                <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{blogData.aboutBusiness.title}</h4>
                                                {blogData.aboutBusiness.map((about, index) => (
                                                    <div key={index}>
                                                        <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{about.title}</h4>
                                                        <p>{about.content}</p>
                                                    </div>
                                                ))}
                                                <div className="wt-post-discription">
                                                    {blockquoteData.map((data, index) => (
                                                        <CustomBlockQuote key={index} quote={data.quote} author={data.author} />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="post-single-list">
                                                <ul className="description-list-2">
                                                    {blogData.listItems.map((item, index) => (
                                                        <li key={index}>
                                                            <i className="feather-check" />
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-navigation m-t30">
                                        <div className="post-nav-links" style={{ display: "block" }}>
                                            <NavLink to={publicUser.blog.GRID1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="post-nav-item nav-post-prev" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="nav-post-arrow" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />이전 페이지
                                                </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogDetail6Page;
