import SectionContact from "./section-contact";
import SectionLocation from "./section-location";
import SectionProfile from "./section-profile";

function SectionEmployersCandidateSidebar({ type }) {
    return (
        <>
            <div className="side-bar-2">
                <div className="twm-s-contact-wrap mb-5">
                    <SectionContact />
                </div>
            </div>
        </>
    )
}

export default SectionEmployersCandidateSidebar;