import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";

function CandidateDetail14Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "입학",
            title: "공부를 하고 싶은 사람들을 위한 비자",
            image: "images/candidates/pic14.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutMeText = (
            <>
                <p>D-10 비자를 소지한 외국인이 한국에서 재입학하여 D-2 비자를 받을 수 있습니다. 이를 위해 필요한 절차와 주의사항에 대해 안내해 드리겠습니다.</p>
                
                <p>D-10 비자 소지자가 한국에서 학업을 재개하여 D-2 비자를 받으려면 먼저 대학에 재입학 신청을 하고 학교에서 발급하는 입학증명서를 획득해야 합니다. 이후, 해당 서류와 관련 서류를 한국 이민국에 제출하여 비자 신청을 진행해야 합니다.</p>
                
                <p>재입학 및 D-2 비자 신청 시에는 필요한 서류를 정확하게 제출하고 관련 규정을 준수해야 합니다. 또한, 비자 발급까지의 시간을 충분히 고려하여 신청해야 합니다.</p>
            </>
        );
        
        const personalCharacteristics = [
            "대학 재입학 신청 절차 숙지",
            "학교 발급 입학증명서 획득",
            "한국 이민국 비자 신청 절차 이해",
        ];
        
        const workExperienceItems = [
            {
                title: "스터디인코리아",
                position: "한국 대학의 공식 홈페이지 및 재입학 신청 및 관련 정보를 제공합니다. 학교별로 절차와 요구 사항이 다를 수 있으므로 해당 학교의 공식 홈페이지를 참고하여 신청서 및 입학증명서 발급 관련 정보를 확인하세요.",
                contactInfo: "https://www.studyinkorea.go.kr/",
                description: "바로가기 링크",
                cvUrl: null,
            },
        ];
    
        return (
            <>
                <div className="section-full p-b90 bg-white">
                    <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
    
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {aboutMeText}
                                            <div>
                                                <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 주의사항</h4>
                                                <ul className="description-list-2">
                                                    {personalCharacteristics.map((characteristic, index) => (
                                                        <li key={index}>
                                                            <i className="feather-check" />
                                                            {characteristic}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 서비스 안내</h4>
                                        <div className="twm-timing-list-wrap">
                                            {workExperienceItems.map((item, index) => (
                                                <div className="twm-timing-list" key={index}>
                                                    <div className="twm-time-list-title">{item.title}</div>
                                                    <div className="twm-time-list-position">{item.position}</div>
                                                    <div className="twm-time-list-discription">
                                                        <p>{item.description}</p>
                                                    </div>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {item.contactInfo && <NavLink to={item.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">홈페이지 이동</NavLink>}
                                                        {item.cvUrl && <a href={item.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="post-navigation m-t30">
                                    <div className="post-nav-links"  style={{display:"block"}}>
                                        <NavLink to={publicUser.candidate.LIST} style={{display:"flex",justifyContent:"center", alignItems:"center"}}>
                                        <div className="post-nav-item nav-post-prev" style={{width: "100%",  display:"flex", justifyContent:"center", alignItems:"center"}}>
                                            <div className="nav-post-arrow" style={{ display:"flex", justifyContent:"center", alignItems:"center"}} >
                                                    <i className="fa fa-angle-left" style={{marginRight:"10px"}}/>이전 페이지
                                            </div>
                                        </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

export default CandidateDetail14Page;
