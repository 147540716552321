import JobZImage from "../jobz-img";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../globals/route-names";
import { useState } from "react";

function Header1({ _config }) {

    const [menuActive, setMenuActive] = useState(false);

    // 버튼을 클릭할 때 메뉴를 열고 닫는 핸들러
  function handleNavigationClick() {
    setMenuActive(!menuActive); // 항상 true 또는 false로 변경
  }


  // NavLink를 클릭했을 때 메뉴를 닫는 핸들러
  function handleNavLinkClick(event) {
    if (event.target.pathname === window.location.pathname) {
        window.location.reload(); // 현재 URL과 NavLink의 URL이 같으면 페이지 다시로드
    } else {
        setMenuActive(false); // NavLink를 클릭하면 항상 false로 변경
    }
}

return (
    <>
        <header className={"site-header " + _config.style + " mobile-sider-drawer-menu " + (menuActive ? "active" : "") }>
            <div className="sticky-header main-bar-wraper navbar-expand-lg">
                <div className="main-bar">
                    <div id="google_translate_element"></div>
                    <div className="container-fluid clearfix">
                        <div className="logo-header">
                            <div className="logo-header-inner logo-header-one">
                                <NavLink to={publicUser.HOME1}>
                                    {
                                        _config.withBlackLogo
                                            ?
                                            <JobZImage src="images/logo-12.png" alt="" />
                                            :
                                            (
                                                _config.withWhiteLogo
                                                    ?
                                                    <JobZImage src="images/logo-white.png" alt="" />
                                                    :
                                                    (
                                                        _config.withLightLogo ?
                                                            <>
                                                                <JobZImage id="skin_header_logo_light" src="images/logo-light-3.png" alt="" className="default-scroll-show" />
                                                                <JobZImage id="skin_header_logo" src="images/logo-dark.png" alt="" className="on-scroll-show" />
                                                            </> :
                                                            <JobZImage id="skin_header_logo" src="images/logo-dark.png" alt="" />
                                                    )
                                            )
                                    }
                                </NavLink>
                            </div>
                        </div>
                        {/* NAV Toggle Button */}
                        <button id="mobile-side-drawer"
                            data-target=".header-nav"
                            data-toggle="collapse"
                            type="button"
                            className="navbar-toggler collapsed"
                            onClick={handleNavigationClick}
                        >
                            <span className="sr-only">Toggle navigation</span>
                            <span className="icon-bar icon-bar-first" />
                            <span className="icon-bar icon-bar-two" />
                            <span className="icon-bar icon-bar-three" />
                        </button>
                        {/* MAIN Vav */}
                        <div className="nav-animation header-nav navbar-collapse collapse d-flex justify-content-center">
                            <ul className=" nav navbar-nav">
                                <li className="has-mega-menu"><NavLink to={publicUser.candidate.DETAIL1} onClick={handleNavLinkClick} style={{fontWeight:"bold", borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}>역사 문화 및 생활, 축제</NavLink></li>
                                <li className="has-mega-menu"><NavLink to={publicUser.candidate.DETAIL2} onClick={handleNavLinkClick} style={{fontWeight:"bold", borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}>주거 환경, 계약</NavLink></li>
                                <li className="has-mega-menu"><NavLink to={publicUser.candidate.DETAIL3} onClick={handleNavLinkClick} style={{fontWeight:"bold", borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}>식생활 및 식료품 구매</NavLink></li>
                                <li className="has-mega-menu"><NavLink to={publicUser.candidate.DETAIL4} onClick={handleNavLinkClick} style={{fontWeight:"bold", borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}>의료 서비스 이용</NavLink></li>
                                <li className="has-mega-menu"><NavLink to={publicUser.candidate.DETAIL5} onClick={handleNavLinkClick} style={{fontWeight:"bold", borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}>교통 및 편의시설</NavLink></li>
                                <li className="has-mega-menu"><NavLink to={publicUser.candidate.DETAIL6} onClick={handleNavLinkClick} style={{fontWeight:"bold", borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}>유학생활</NavLink></li>
                                <li className="has-mega-menu"><NavLink to={publicUser.candidate.DETAIL7} onClick={handleNavLinkClick} style={{fontWeight:"bold", borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}>안전 및 법률</NavLink></li>
                                <li className="has-mega-menu"><NavLink to={publicUser.candidate.DETAIL8} onClick={handleNavLinkClick} style={{fontWeight:"bold", borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}>휴대폰 사용 방법</NavLink></li>
                                <li className="has-mega-menu"><NavLink to={publicUser.candidate.DETAIL9} onClick={handleNavLinkClick} style={{fontWeight:"bold", borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}>아르바이트</NavLink></li>
                                <li className="has-mega-menu"><NavLink to={publicUser.candidate.DETAIL10} onClick={handleNavLinkClick} style={{fontWeight:"bold", borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}>취업</NavLink></li>
                                <li className="has-mega-menu"><NavLink to={publicUser.candidate.DETAIL11} onClick={handleNavLinkClick} style={{fontWeight:"bold", borderBottom: "1px solid rgba(0, 0, 0, 0.3)"}}>상담 창구</NavLink></li>
                            </ul>
                        </div>
                    </div>
                </div>
                {/* SITE Search */}
                <div id="search">
                    <span className="close" />
                    <form role="search" id="searchform" action="/search" method="get" className="radius-xl">
                        <input className="form-control" name="q" type="search" placeholder="Type to search" />
                        <span className="input-group-append">
                            <button type="button" className="search-btn">
                                <i className="fa fa-paper-plane" />
                            </button>
                        </span>
                    </form>
                </div>
            </div>
        </header>

    </>
)
}

export default Header1;