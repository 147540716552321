import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import SectionBlogsSidebar from "../../sections/blogs/sidebar/section-blogs-sidebar";

function BlogDetail5Page() {
    // 블로그 데이터 변수 정의
    const blogData = {
        title: "행동",
        imageSrc: "images/blog/latest/bg5.png",
        description: [
            "한국의 행동 문화는 공동체 의식과 상호 존중을 기반으로 합니다. 한국인들은 다른 사람들을 배려하고 소통하는 데에 중요한 가치를 두고 있습니다. 이에 따라 한국에서는 특정한 예절과 행동 규칙이 존재합니다.",
            "예를 들어, 한국에서는 먼저 인사를 하는 것이 중요합니다. 새로운 사람을 만났을 때는 공손하고 정중한 태도로 인사를 주고 받는 것이 예절입니다. 또한, 노인이나 상사 앞에서는 경의를 표하는 것이 중요하며, 손을 양손으로 받쳐주는 것이 일반적인 예절입니다.",
            "또한, 한국의 행동 문화에서는 공공장소에서의 행동에도 특별한 주의를 기울입니다. 공공장소에서는 시끄럽거나 남의 시선을 끄는 행동을 자제해야 하며, 쓰레기를 올바른 장소에 버리고 시설물을 소중히 다뤄야 합니다."
        ],
        aboutBusiness: [
            {
                title: "인사의 중요성",
                content: "한국에서는 새로운 사람을 만났을 때 공손하고 정중한 태도로 인사하는 것이 중요합니다. 인사는 상호 존중과 소통의 시작입니다."
            },
            {
                title: "노인과 상사 앞에서의 예의",
                content: "한국의 행동 문화에서는 노인이나 상사 앞에서는 경의를 표하는 것이 중요합니다. 손을 양손으로 받쳐주는 것은 상대방에 대한 존중과 예의를 나타내는 중요한 행동입니다."
            },
            {
                title: "공공장소에서의 예절",
                content: "공공장소에서는 시끄럽거나 남의 시선을 끄는 행동을 자제해야 하며, 쓰레기를 올바른 장소에 버리고 시설물을 소중히 다뤄야 합니다. 공공장소에서의 예절을 지키는 것은 공동체의 안전과 편안함을 유지하는 데에 도움이 됩니다."
            }
        ],
        listItems: [
            "한국의 행동 문화는 공동체 의식과 상호 존중을 기반으로 합니다.",
            "새로운 사람을 만날 때 공손하고 정중한 태도로 인사하는 것이 중요합니다.",
            "공공장소에서는 시끄럽거나 남의 시선을 끄는 행동을 자제해야 하며, 쓰레기를 올바른 장소에 버리고 시설물을 소중히 다뤄야 합니다."
        ]
    };
    
    const blockquoteData = [
        {
            author: "예은",
            quote: "새로운 사람을 만났을 때 공손하고 정중하게 인사해야 해요."
        },
        {
            author: "성우",
            quote: "노인이나 상사 앞에서는 손을 양손으로 받쳐주는 것이 예의입니다."
        }
    ];

    function CustomBlockQuote({ quote, author }) {
        return (
            <blockquote>
                <strong style={{fontWeight:"bold"}}>{author}</strong>
                <p><span>"</span>- {quote}</p>
            </blockquote>
        );
    }
    
    useEffect(() => {
        loadScript("js/custom.js");
    });

    return (
        <>
            <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    {/* BLOG SECTION START */}
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                {/* BLOG START */}
                                <div className="blog-post-single-outer">
                                    <div className="blog-post-single bg-white">
                                        <div className="wt-post-info">
                                            <div className="wt-post-media m-b30" style={{width: "100%", display:"flex", justifyContent:"center"}}>
                                                <JobZImage src={blogData.imageSrc} alt="" style={{width: "300px"}}/>
                                            </div>
                                            <div className="wt-post-title">
                                                <h3 className="post-title">{blogData.title}</h3>
                                            </div>
                                            <div className="wt-post-discription">
                                                {blogData.description.map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                                <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{blogData.aboutBusiness.title}</h4>
                                                {blogData.aboutBusiness.map((about, index) => (
                                                    <div key={index}>
                                                        <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{about.title}</h4>
                                                        <p>{about.content}</p>
                                                    </div>
                                                ))}
                                                <div className="wt-post-discription">
                                                    {blockquoteData.map((data, index) => (
                                                        <CustomBlockQuote key={index} quote={data.quote} author={data.author} />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="post-single-list">
                                                <ul className="description-list-2">
                                                    {blogData.listItems.map((item, index) => (
                                                        <li key={index}>
                                                            <i className="feather-check" />
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-navigation m-t30">
                                        <div className="post-nav-links" style={{ display: "block" }}>
                                            <NavLink to={publicUser.blog.GRID1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="post-nav-item nav-post-prev" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="nav-post-arrow" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />이전 페이지
                                                </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogDetail5Page;
