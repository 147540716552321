import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import SectionBlogsSidebar from "../../sections/blogs/sidebar/section-blogs-sidebar";

function BlogDetail9Page() {
    // 블로그 데이터 변수 정의
    const blogData = {
        title: "돈",
        imageSrc: "images/blog/latest/bg9.png",
        description: [
            "한국의 돈 문화는 더치페이와 팁 문화가 주요한 특징입니다. 더치페이는 식사나 외식 시 여러 사람이 함께 먹을 때 각자가 자신이 먹은 만큼의 비용을 부담하는 문화를 의미하며, 한국에서는 일반적인 관례로 자리를 떠날 때 더치페이를 실시하는 경우가 많습니다.",
            "팁을 주는 것은 한국에서 흔하지 않습니다. 대부분의 식당이나 카페에서는 팁을 따로 받지 않습니다.",
            "한국인들에게 돈을 줄 때에는 공손하고 예의 바른 태도를 유지하는 것이 중요합니다. 돈을 건넬 때에는 양손으로 받쳐주는 것이 일반적이며, 돈을 받을 때에는 곧장 열지 않고 예의 바르게 받아들이는 것이 좋습니다."
        ],
        aboutBusiness: [
            {
                title: "더치페이 문화",
                content: "한국의 더치페이 문화는 여러 사람이 함께 먹을 때 각자가 자신이 먹은 만큼의 비용을 부담하는 것을 의미합니다. 자리를 떠날 때 더치페이를 실시하는 것이 일반적인 관례입니다."
            },
            {
                title: "팁 문화",
                content: "팁을 주는 것은 일반적이지 않으며, 필요한 경우에는 고맙다는 뜻으로 금액을 추가하여 직원에게 직접 전달할 수 있습니다."
            },
            {
                title: "돈을 건넬 때와 받을 때의 예절",
                content: "한국인들에게 돈을 건넬 때에는 양손으로 받쳐주는 것이 일반적이며, 돈을 받을 때에는 곧장 열지 않고 예의 바르게 받아들이는 것이 좋습니다."
            }
        ],
        listItems: [
            "한국의 돈 문화는 더치페이와 팁 문화가 주요한 특징입니다.",
            "돈을 건넬 때에는 양손으로 받쳐주고, 받을 때에는 곧장 열지 않고 예의 바르게 받아들이는 것이 중요합니다."
        ]
    };
    
    const blockquoteData = [
        {
            author: "지훈",
            quote: "더치페이로 계산하자. 각자 부담하는 것이 공평하니까."
        },
    ];

    function CustomBlockQuote({ quote, author }) {
        return (
            <blockquote>
                <strong style={{fontWeight:"bold"}}>{author}</strong>
                <p><span>"</span>- {quote}</p>
            </blockquote>
        );
    }

    useEffect(() => {
        loadScript("js/custom.js");
    });

    return (
        <>
            <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    {/* BLOG SECTION START */}
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                {/* BLOG START */}
                                <div className="blog-post-single-outer">
                                    <div className="blog-post-single bg-white">
                                        <div className="wt-post-info">
                                            <div className="wt-post-media m-b30" style={{width: "100%", display:"flex", justifyContent:"center"}}>
                                                <JobZImage src={blogData.imageSrc} alt="" style={{width: "300px"}}/>
                                            </div>
                                            <div className="wt-post-title">
                                                <h3 className="post-title">{blogData.title}</h3>
                                            </div>
                                            <div className="wt-post-discription">
                                                {blogData.description.map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                                <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{blogData.aboutBusiness.title}</h4>
                                                {blogData.aboutBusiness.map((about, index) => (
                                                    <div key={index}>
                                                        <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{about.title}</h4>
                                                        <p>{about.content}</p>
                                                    </div>
                                                ))}
                                                <div className="wt-post-discription">
                                                    {blockquoteData.map((data, index) => (
                                                        <CustomBlockQuote key={index} quote={data.quote} author={data.author} />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="post-single-list">
                                                <ul className="description-list-2">
                                                    {blogData.listItems.map((item, index) => (
                                                        <li key={index}>
                                                            <i className="feather-check" />
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-navigation m-t30">
                                        <div className="post-nav-links" style={{ display: "block" }}>
                                            <NavLink to={publicUser.blog.GRID1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="post-nav-item nav-post-prev" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="nav-post-arrow" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />이전 페이지
                                                </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogDetail9Page;
