import { NavLink } from "react-router-dom";
import { loadScript, publicUrlFor, default_skin, updateSkinStyle } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import CountUp from "react-countup";
import { useEffect } from "react";

function Home3Page() {

    useEffect(()=>{
        updateSkinStyle(default_skin, true, false)
        loadScript("js/custom.js")
    })

    return (
        <>
            <div className="post-navigation m-t30">
                <div className="post-nav-links"  style={{display:"block"}}>
                    <NavLink to="http://pf.kakao.com/_UxjfVj" target="_blank" rel="noopener noreferrer" style={{display:"flex",justifyContent:"center", alignItems:"center"}}>
                        <div className="post-nav-item nav-post-prev" style={{width: "100%",  display:"flex", justifyContent:"center", alignItems:"center"}}>
                            <div className="nav-post-arrow" style={{ display:"flex", justifyContent:"center", alignItems:"center"}} >
                                무엇이든 물어보세요<i className="fa fa-angle-right" style={{marginLeft:"10px"}}/>
                            </div>
                        </div>
                    </NavLink>
                </div>
            </div>
        </>
    )
}

export default Home3Page;