import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";
import Home2Page from "../home/index2";
import Home3Page from "../home/index3";

function CandidateDetail1Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "기타 학업, 생활, 행정, 취업 상담 창구",
            title: "문의",
            image: "images/candidates/study11.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutText = [
            {
                title:"1) 한국 역사와 문화",
                img: "images/candidates/study1.png",
                discription:(
                    <>
                        <p>한국은 동북아시아에 위치한 반도 국가로, 유구한 역사와 전통을 자랑합니다. 한민족은 기원전 3천년경부터 이 땅에서 역사를 이어왔습니다. 고구려, 백제, 신라 세 나라가 있다가 나중에 고려로 통일되었어요. 그 후 조선 시대가 600년 이상 지속되면서 한국의 전통문화가 꽃을 피웠답니다.</p>
                        <p>우리나라에는 아름다운 문화유산이 정말 많아요. 서울 한복판에 있는 경복궁은 조선시대 왕궁으로 아름다운 건축물과 정원이 있죠. 또 불교 문화유산인 석굴암, 불국사, 해인사 등도 유명해요. 이런 문화유산들이 유네스코 세계문화유산으로 지정되어 있어요.</p>
                        <p>한국 사람들은 명절을 아주 중요하게 여겨요. 설날에는 가족들이 모여서 윷놀이를 하고 전통 음식인 떡국을 먹어요. 추석에는 보름달을 감상하며 조상에게 감사기도를 드려요.</p>
                        <p>한국에는 다양한 전통 음식도 있어요. 불고기, 비빔밥, 잡채 등 기본 밥과 반찬이 푸짐해요. 매운맛 매력이 큰 김치는 한국의 대표 반찬이에요. 한과, 식혜, 수정과 등 전통 디저트도 있죠.</p>
                        <p>재미있는 전통 민속놀이도 많답니다. 팽이치기, 제기차기, 널뛰기, 연날리기 등 다양한 활동들이 있어요. 지역별로 열리는 전통 축제에서 이런 민속놀이를 직접 체험해볼 수 있죠.</p>
                        <p>이렇게 한국은 오랜 역사와 찬란한 문화유산, 다채로운 전통문화를 가지고 있어요.</p>
                    </>
                ),
                Static_items:[
                    {
                        img: null,
                        item_title: null,
                        item_discription: null,
                        item_inner: [
                            (
                                <>
                                    <p>예시</p>
                                </>
                            )
                        ],
                    },
                ]
            }
        ]
        const workExperienceItems = [
            {
                title: "부동산 사이트1",
                site:[
                    {
                        siteName : "다방",
                        contactInfo: "https://www.dabangapp.com"
                    },
                    {
                        siteName : "직방",
                        contactInfo: "https://www.www.zigbang.com"
                    },
                ]
            },
        ];

        return (
            <>
                <div className="section-full p-b90 bg-white">
                <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {/*items*/}
                                            {aboutText.map((item, index) => (
                                                item && (
                                                    <div key={index}>
                                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{item.title}</h4>
                                                        {item.img && <JobZImage src={item.img} alt="#" />}
                                                        <div>
                                                            {item.discription}
                                                        </div>
                                                        <ul className="description-list-2">
                                                            {item.Static_items && item.Static_items.map((innerItem, innerIndex) => (
                                                                innerItem && (
                                                                    <li key={innerIndex}>
                                                                        <i className="feather-check" />
                                                                        <span style={{fontWeight:"bold"}}>{innerItem.item_title}</span>
                                                                        <div>
                                                                        {innerItem.img && <JobZImage src={innerItem.img} alt="#" />}
                                                                        </div>
                                                                        <div>
                                                                            {innerItem.item_discription}
                                                                        </div>
                                                                        <ul className="description-list-2">
                                                                            {innerItem.item_inner.map((subItem, subIndex) => (
                                                                                <li key={subIndex}>
                                                                                    {subItem}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            ))}
                                        {/*items*/}
                                    </div>
                                    
                                    <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 웹사이트</h4>
                                    <div className="twm-timing-list-wrap">
                                        {workExperienceItems.map((item, index) => (
                                        <div className="twm-timing-list" key={index}>
                                            <div className="twm-time-list-title">{item.title}</div>
                                            {item.site.map((siteItem, siteIndex) => (
                                                <div key={siteIndex}>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {siteItem.contactInfo && <NavLink to={siteItem.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">{siteItem.siteName} 바로가기</NavLink>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <Home2Page />
                            <Home3Page />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateDetail1Page;
