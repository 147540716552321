import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";
import Home2Page from "../home/index2";
import Home3Page from "../home/index3";

function CandidateDetail1Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "안전 및 법률",
            title: "생활",
            image: "images/candidates/study7.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutText = [
            {
                title:"1) 범죄 예방 및 대처 방안",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: "범죄 예방",
                        item_discription: (
                            <>
                                <p>밤길은 가급적 혼자 다니지 말고, 인적이 많은 길을 이용하세요.</p>
                                <p>현금이나 귀중품은 최소한으로 소지하고, 도난 주의가 필요합니다.</p>
                                <p>낯선 사람을 잘 믿거나 따라가지 마세요.</p>
                                <p>비상시를 대비해 주변 경찰서, 대사관 번호를 숙지해 두세요.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "성범죄 예방",
                        item_discription: (
                            <>
                                <p>약물범죄 예방에도 주의를 기울여야 합니다.</p>
                                <p>낯선 사람이 제공하는 음료수를 조심해야 합니다.</p>
                                <p>위험한 장소나 상황에서는 119 또는 1366으로 신고하세요.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "교통사고 예방",
                        item_discription: (
                            <>
                                <p>길을 건널 때는 신호등을 지켜야 합니다.</p>
                                <p>보행자 전용 도로가 아닌 곳에서는 차량 주의가 필요합니다.</p>
                                <p>음주운전은 절대 금물입니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "범죄 피해 시 대처",
                        item_discription: (
                            <>
                                <p>가까운 경찰서나 119에 신고하세요.</p>
                                <p>범죄피해를 증명할 수 있는 자료를 확보하세요.</p>
                                <p>외국인 지원센터나 대사관에 연락하여 도움을 요청하세요.</p>
                                <p>피해 상담과 구조 요청이 가능한 기관을 활용하세요.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            },
            {
                title:"2) 기본 법률 상식",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: "마약 및 약물 관련 법규",
                        item_discription: (
                            <>
                                <p>한국에서는 마약, 향정신성 의약품 불법 소지/투약이 엄격히 금지됩니다.</p>
                                <p>처벌 수위가 매우 높으므로 절대 관련 범죄에 가담해서는 안 됩니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "폭력 및 성범죄 관련 법규",
                        item_discription: (
                            <>
                                <p>폭행, 협박, 성폭력 등 범죄 행위는 엄중히 처벌받습니다.</p>
                                <p>데이트 폭력, 디지털 성범죄 등도 주의해야 합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "도박 관련 법규",
                        item_discription: (
                            <>
                                <p>한국에서는 일반 도박이 금지되어 있습니다.</p>
                                <p>합법적인 카지노를 제외하고는 도박을 해서는 안 됩니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "음주 운전 금지",
                        item_discription: (
                            <>
                                <p>음주운전은 법으로 철저히 규제되고 있습니다.</p>
                                <p>만취 상태로 운전할 경우 중형에 처해질 수 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "공공장소 에티켓",
                        item_discription: (
                            <>
                                <p>공공장소에서 소리를 지르거나 싸움을 벌이는 행위는 자제해야 합니다.</p>
                                <p>공공기물 손괴, 떠들어대는 행위 등도 피해야 합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "흡연 규제",
                        item_discription: (
                            <>
                                <p>캠퍼스, 식당 등 대부분 실내 공간에서는 전면 금연입니다.</p>
                                <p>지정된 흡연구역에서만 흡연이 가능합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "성폭력 범죄",
                        item_discription: (
                            <>
                                <p>강간, 강제추행 등 성폭력 범죄는 징역형이 주어집니다.</p>
                                <p>친밀한 관계라도 강제성 행위는 성폭력으로 간주됩니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "성희롱",
                        item_discription: (
                            <>
                                <p>성적 욕구를 불러일으키는 말과 행동으로 상대방에게 고통을 주는 행위입니다.</p>
                                <p>직장, 학교 등 다양한 영역에서 발생할 수 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "데이트 폭력",
                        item_discription: (
                            <>
                                <p>친밀한 관계에서 발생하는 구체적, 신체적, 언어적, 정서적 폭력입니다.</p>
                                <p>모욕, 협박, 폭행, 스토킹 등 행위가 해당됩니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "디지털 성범죄",
                        item_discription: (
                            <>
                                <p>불법촬영, 허위영상물 유포, 리벤지포르노 등이 포함됩니다.</p>
                                <p>사이버 공간에서의 성적 괴롭힘, 유언비어 등도 처벌 대상입니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            },
            {
                title:"3) 긴급 상황 대처",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: "긴급신고 번호 숙지",
                        item_discription: (
                            <>
                                <p>화재/구조 119</p>
                                <p>범죄/사고 신고 112</p>
                                <p>의료 상황 1339</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "범죄/사고 발생 시",
                        item_discription: (
                            <>
                                <p>안전한 곳으로 대피하고 경찰(112)에 신고하세요.</p>
                                <p>가능하다면 증거를 수집하고, 주변인 연락처를 확보하세요.</p>
                                <p>학교 측과 대사관에도 상황을 전달하세요.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "화재 발생 시",
                        item_discription: (
                            <>
                                <p>대피로를 따라 바깥으로 대피하고 119에 신고하세요.</p>
                                <p>엘리베이터 대신 계단을 이용하세요.</p>
                                <p>건물 밖 대피장소에 모여 구조를 기다리세요.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "응급 상황 시",
                        item_discription: (
                            <>
                                <p>119 구급대를 불러 병원 이송을 요청하세요.</p>
                                <p>친구/학교 관계자에게 상황을 알리세요.</p>
                                <p>여권, 의료보험 증명서를 준비하세요.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "자연재해 발생 시",
                        item_discription: (
                            <>
                                <p>라디오, TV를 통해 최신 정보를 확인하세요.</p>
                                <p>실내에 머무르고, 비상 집결지로 대피하세요.</p>
                                <p>외국인 등록증, 여권, 긴급 약품 등을 준비하세요.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "기타 유용한 번호",
                        item_discription: (
                            <>
                                <p>외국인종합안내센터 1345</p>
                                <p>한국관광공사 해외체류자 안내 1330</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            },
        ]
        const workExperienceItems = [
            {
                title: "범죄 신고",
                site:[
                    {
                        siteName : "경찰 민원 사이트",
                        contactInfo: "https://www.police.go.kr/www/security/report/report01.jsp"
                    },
                ]
            },
        ];

        return (
            <>
                <div className="section-full p-b90 bg-white">
                <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {/*items*/}
                                            {aboutText.map((item, index) => (
                                                item && (
                                                    <div key={index}>
                                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{item.title}</h4>
                                                        {item.img && <JobZImage src={item.img} alt="#" />}
                                                        <div>
                                                            {item.discription}
                                                        </div>
                                                        <ul className="description-list-2">
                                                            {item.Static_items && item.Static_items.map((innerItem, innerIndex) => (
                                                                innerItem && (
                                                                    <li key={innerIndex}>
                                                                        <i className="feather-check" />
                                                                        <span style={{fontWeight:"bold"}}>{innerItem.item_title}</span>
                                                                        <div>
                                                                        {innerItem.img && <JobZImage src={innerItem.img} alt="#" />}
                                                                        </div>
                                                                        <div>
                                                                            {innerItem.item_discription}
                                                                        </div>
                                                                        <ul className="description-list-2">
                                                                            {innerItem.item_inner.map((subItem, subIndex) => (
                                                                                <li key={subIndex}>
                                                                                    {subItem}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            ))}
                                        {/*items*/}
                                    </div>
                                    
                                    <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 웹사이트</h4>
                                    <div className="twm-timing-list-wrap">
                                        {workExperienceItems.map((item, index) => (
                                        <div className="twm-timing-list" key={index}>
                                            <div className="twm-time-list-title">{item.title}</div>
                                            {item.site.map((siteItem, siteIndex) => (
                                                <div key={siteIndex}>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {siteItem.contactInfo && <NavLink to={siteItem.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">{siteItem.siteName} 바로가기</NavLink>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <Home2Page />
                            <Home3Page />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateDetail1Page;
