import JobZImage from "../../../../common/jobz-img";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import { loadScript, publicUrlFor, default_skin, updateSkinStyle } from "../../../../../globals/constants";
import CountUp from "react-countup";
import { useEffect } from "react";
import Home2Page from "./index2";

function Home1Page() {

    useEffect(()=>{
        updateSkinStyle(default_skin, true, false)
        loadScript("js/custom.js")
    })

    const clientSites = [
        {
            link: "https://www.naver.com/",
            imageSrc: "images/client-logo2/w2.png",
            title: "국민 검색 사이트"
        },
        {
            link: "https://www.hikorea.go.kr/board/BoardNtcDetailR.pt?BBS_SEQ_GB_CD=&BBS_SEQ=1&BBS_GB_CD=BS10&NTCCTT_SEQ=1062&page=1",
            imageSrc: "images/client-logo2/w1.png",
            title: "비자 가이드북"
        },
        {
            link: "https://www.immigration.go.kr/immigration/1473/subview.do",
            imageSrc: "images/client-logo2/w3.png",
            title: "출입국 뉴스"
        },
        {
            link: "https://www.topik.go.kr",
            imageSrc: "images/client-logo2/w4.png",
            title: "토픽(TOPIK)"
        },
        {
            link: "https://www.kiiptest.org/",
            imageSrc: "images/client-logo2/w5.png",
            title: "사회통합프로그램(KIIP)"
        },
        {
            link: "https://www.iksi.or.kr/",
            imageSrc: "images/client-logo2/w6.png",
            title: "세종학당(SKA)"
        },
    ];

    const workExperienceItems = [
        {
            title: null,
            site:[
                {
                    img:"images/manager.png",
                    siteName : "상담센터 바로가기",
                    contactInfo: "http://pf.kakao.com/_UxjfVj"
                },
            ]
        },
    ];
    return (
        <>
                        {/*Banner Start*/}
                        <div className="twm-home7-banner-section site-bg-white bg-cover" style={{ backgroundImage: `url(${publicUrlFor("images/main-slider/slider1/h7-banner.png")})` }}>
                <div className="container">
                    <div className="twm-home7-inner-section">
                        <div className="twm-bnr-mid-section">
                        <div className="twm-bnr-title-large" style={{fontWeight:"bold",color:"black"}}>한국 생활, <span className="text-clr-pink">한번에 보기</span></div>
            <Home2Page />
            
                {workExperienceItems.map((item, index) => (
                    <div key={index} style={{margin:"0 auto", display:"flex",justifyContent:"center",alignItems:"center",flexDirection:"column"}}>
                        <div className="twm-time-list-title">{item.title}</div>
                        {item.site.map((siteItem, siteIndex) => (
                            <div key={siteIndex}>
                                {siteItem.contactInfo && <NavLink to={siteItem.contactInfo} target="_blank" rel="noopener noreferrer" style={{display:"flex"}}>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                        <JobZImage src={siteItem.img} alt="#" style={{marginRight:"10px", width: "20vw"}}/>
                                        <div className="site-button m-3" style={{fontSize:"30px"}}>
                                            {siteItem.siteName}
                                        </div>
                                    </div>
                                </NavLink>}
                            </div>
                        ))}
                    </div>
                ))}
            </div>
            </div>
            </div>
            </div>
            {/* TOP COMPANIES START */}
            <div className="section-full p-t120 p-b0 site-bg-white twm-companies-wrap twm-companies-wrap-h-page-7 pos-relative">
                <div className="twm-companies-wrap-bg-block" />
                {/* title="" START*/}
                <div className="section-head center wt-small-separator-outer content-white">
                    <h2 className="wt-title">알아두면 좋은 사이트</h2>
                </div>
                {/* title="" END*/}
                <div className="container ">
                    <div className="twm-companies-h-page-7">
                        <div className="section-content">
                            <div className="owl-carousel home-client-carousel3 owl-btn-vertical-center" style={{textAlign:"center"}}>
                            {clientSites.map((site, index) => (
                                <div className="item" key={index}>
                                    <NavLink to={site.link} target="_blank" rel="noopener noreferrer">
                                        <div className="ow-client-logo mt-4" style={{display:"flex",flexDirection:"column"}}>
                                                <div className="client-logo client-logo-media">
                                                    <div>
                                                        <JobZImage src={site.imageSrc} alt="" />
                                                    </div>
                                                </div>
                                                <div className="mt-3">
                                                    {site.title}
                                                </div>
                                        </div>
                                    </NavLink>
                                </div>
                            ))}
                            </div>
                        </div>
                        <div className="twm-company-approch2-outer">
                            <div className="twm-company-approch2">
                                <div className="row">
                                    {/*block 1*/}
                                    <div className="">
                                        <div className="counter-outer-two">
                                            <div className="icon-content">
                                                <div className="tw-count-number site-text-primary">
                                                    <span className="counter">
                                                    </span>클릭하면 접속할 수 있습니다</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* TOP COMPANIES END */}
            {/* CANDIDATES START */}
            <div className="section-full p-t120 p-b10 site-bg-white twm-candidate-h-page7-wrap pos-relative ">
                {/*
                <div className="container">
                    <div className="section-head center wt-small-separator-outer">
                        <div className="wt-small-separator site-text-primary">
                            <div>Candidates</div>
                        </div>
                        <h2 className="wt-title">좋은 서비스를 제공하겠습니다</h2>
                    </div>
                </div>
                */}
            </div>
            {/* CANDIDATES END */}
        </>
    )
}

export default Home1Page;