import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import SectionBlogsSidebar from "../../sections/blogs/sidebar/section-blogs-sidebar";

function BlogDetail2Page() {
    // 블로그 데이터 변수 정의
    const blogData = {
        title: "공공장소",
        imageSrc: "images/blog/latest/bg2.png",
        description: [
            "한국에는 다양한 공공장소가 있습니다. 그중 대표적인 것은 공원, 도서관, 박물관, 지하철 등이 있습니다. 이러한 공공장소는 사람들이 편안하게 쉴 수 있도록 만들어진 장소로, 누구나 자유롭게 이용할 수 있습니다. 이들 공공장소에서는 특정한 예절을 지키는 것이 중요합니다.",
            "한국의 공공장소에서의 예절은 주변 사람들과의 배려와 친절함을 강조합니다. 공원이나 도서관 등에서는 시끄럽지 않도록 조용히 행동하는 것이 중요하며, 다른 이용자들의 휴식을 방해하지 않아야 합니다. 또한, 공공장소에서는 쓰레기를 올바른 장소에 버리고 시설물을 소중히 다뤄야 합니다.",
            "특히 한국의 지하철은 매우 혼잡한 공간이므로, 타인과의 거리를 적절히 유지하고 좌석을 양보하는 것이 중요합니다. 또한, 소음을 최소화하고 출입구에서는 승객들에게 먼저 내보내는 등의 예의를 지켜야 합니다. 이러한 예절을 지키면서 공공장소를 이용하는 것이 모두에게 편안한 환경을 만들어줄 수 있습니다."
        ],
        aboutBusiness: [
            {
                title: "공공장소 이용 시 조용한 행동",
                content: "한국의 공공장소에서는 주변 사람들의 휴식을 존중하기 위해 조용한 행동을 유지하는 것이 중요합니다. 특히 도서관이나 박물관 등의 장소에서는 시끄럽지 않도록 주의해야 합니다."
            },
            {
                title: "환경 보호를 위한 쓰레기 처리",
                content: "한국의 공공장소에서는 쓰레기를 올바른 장소에 버리고 환경을 보호하는 것이 중요합니다. 쓰레기를 제대로 처리함으로써 깨끗하고 쾌적한 환경을 유지할 수 있습니다."
            },
            {
                title: "지하철에서의 예절",
                content: "한국의 지하철은 혼잡한 공간이므로 다른 승객들과의 거리를 적절히 유지하고 좌석을 양보하는 것이 중요합니다. 또한, 소음을 최소화하고 출입구에서는 승객들에게 먼저 내보내는 등의 예의를 지켜야 합니다."
            }
        ],
        listItems: [
            "한국의 공공장소에서는 주변 사람들과의 배려와 친절함을 강조하는 예절이 중요합니다.",
            "도서관, 박물관 등에서는 시끄럽지 않도록 조용한 행동을 유지해야 합니다.",
            "지하철에서는 혼잡한 상황에서도 다른 승객들을 배려하고 예의를 지켜야 합니다."
        ]
    };
    
    const blockquoteData = [
        {
            author: "지민",
            quote: "도서관에서는 책을 읽을 때 조용히 해야 해."
        },
        {
            author: "영희",
            quote: "지하철에서는 먼저 내보내는 것이 예의야."
        }
    ];

    function CustomBlockQuote({ quote, author }) {
        return (
            <blockquote>
                <strong style={{fontWeight:"bold"}}>{author}</strong>
                <p><span>"</span>- {quote}</p>
            </blockquote>
        );
    }

    useEffect(() => {
        loadScript("js/custom.js");
    });

    return (
        <>
            <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    {/* BLOG SECTION START */}
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                {/* BLOG START */}
                                <div className="blog-post-single-outer">
                                    <div className="blog-post-single bg-white">
                                        <div className="wt-post-info">
                                            <div className="wt-post-media m-b30" style={{width: "100%", display:"flex", justifyContent:"center"}}>
                                                <JobZImage src={blogData.imageSrc} alt="" style={{width: "300px"}}/>
                                            </div>
                                            <div className="wt-post-title">
                                                <h3 className="post-title">{blogData.title}</h3>
                                            </div>
                                            <div className="wt-post-discription">
                                                {blogData.description.map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                                <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{blogData.aboutBusiness.title}</h4>
                                                {blogData.aboutBusiness.map((about, index) => (
                                                    <div key={index}>
                                                        <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{about.title}</h4>
                                                        <p>{about.content}</p>
                                                    </div>
                                                ))}
                                                <div className="wt-post-discription">
                                                    {blockquoteData.map((data, index) => (
                                                        <CustomBlockQuote key={index} quote={data.quote} author={data.author} />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="post-single-list">
                                                <ul className="description-list-2">
                                                    {blogData.listItems.map((item, index) => (
                                                        <li key={index}>
                                                            <i className="feather-check" />
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-navigation m-t30">
                                        <div className="post-nav-links" style={{ display: "block" }}>
                                            <NavLink to={publicUser.blog.GRID1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="post-nav-item nav-post-prev" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="nav-post-arrow" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />이전 페이지
                                                </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogDetail2Page;
