import JobZImage from "../../../../common/jobz-img";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import { loadScript, publicUrlFor, default_skin, updateSkinStyle } from "../../../../../globals/constants";
import CountUp from "react-countup";
import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";

function Home2Page() {
    const recommendedJobs = [
        {
            class:"one",
            title: "역사 문화 및 생활",
            description: "문화",
            webSiteLink: publicUser.candidate.DETAIL1,
            googlePlayLink: null,
            appStoreLink: null,
            imageSrc: "images/candidates/study1.png"
        },
        {
            class:"two",
            title: "주거 환경 & 주거지 계약",
            description: "생활",
            webSiteLink: publicUser.candidate.DETAIL2,
            googlePlayLink: null,
            appStoreLink: null,
            imageSrc: "images/candidates/study2.png"
        },
        {
            class:"two",
            title: "식생활 및 식료품 구매",
            description: "생활",
            webSiteLink: publicUser.candidate.DETAIL3,
            googlePlayLink: null,
            appStoreLink: null,
            imageSrc: "images/candidates/study3.png"
        },
        {
            class:"two",
            title: "의료 서비스 이용",
            description: "생활",
            webSiteLink: publicUser.candidate.DETAIL4,
            googlePlayLink: null,
            appStoreLink: null,
            imageSrc: "images/candidates/study4.png"
        },
        {
            class:"two",
            title: "교통 및 생활 편의시설",
            description: "생활",
            webSiteLink: publicUser.candidate.DETAIL5,
            googlePlayLink: null,
            appStoreLink: null,
            imageSrc: "images/candidates/study5.png"
        },
        {
            class:"two",
            title: "유학 생활",
            description: "생활",
            webSiteLink: publicUser.candidate.DETAIL6,
            googlePlayLink: null,
            appStoreLink: null,
            imageSrc: "images/candidates/study6.png"
        },
        {
            class:"two",
            title: "안전 및 법률",
            description: "생활",
            webSiteLink: publicUser.candidate.DETAIL7,
            googlePlayLink: null,
            appStoreLink: null,
            imageSrc: "images/candidates/study7.png"
        },
        {
            class:"two",
            title: "휴대폰 사용 방법",
            description: "생활",
            webSiteLink: publicUser.candidate.DETAIL8,
            googlePlayLink: null,
            appStoreLink: null,
            imageSrc: "images/candidates/study8.png"
        },
        {
            class:"three",
            title: "아르바이트",
            description: "일자리",
            webSiteLink: publicUser.candidate.DETAIL9,
            googlePlayLink: null,
            appStoreLink: null,
            imageSrc: "images/candidates/study9.png"
        },
        {
            class:"three",
            title: "취업",
            description: "일자리",
            webSiteLink: publicUser.candidate.DETAIL10,
            googlePlayLink: null,
            appStoreLink: null,
            imageSrc: "images/candidates/study10.png"
        },
        {
            class:"five",
            title: "기타 학업, 생활, 행정, 취업 상담 창구",
            description: "문의",
            webSiteLink: publicUser.candidate.DETAIL11,
            googlePlayLink: null,
            appStoreLink: null,
            imageSrc: "images/candidates/study11.png"
        },
    ];

    const location = useLocation();

    useEffect(() => {
        const setEqualHeight = () => {
            const topContentWraps = document.querySelectorAll(".top-content-wrap");
            let maxHeight = 220;
            topContentWraps.forEach((element) => {
                const height = element.offsetHeight;
                if (height > maxHeight) {
                    maxHeight = height;
                }
            });
            topContentWraps.forEach((element) => {
                element.style.height = `${maxHeight}px`;
            });
        };

        // 페이지 로드 후 모든 리소스가 로드된 뒤에 setEqualHeight 함수 실행
        window.onload = () => {
            setEqualHeight();
            // 이벤트 리스너 추가
            window.addEventListener("resize", setEqualHeight);
        };
    
        // cleanup 함수
        return () => {
            window.removeEventListener("resize", setEqualHeight);
        };
    }, [location]); // location을 의존성 배열에 추가하여 페이지 이동 시에도 다시 렌더링되도록 합니다.

    return (
        <>
            {/* Recommended Jobs SECTION START */}
            <div className="section-full p-b10 twm-recommended-Jobs-wrap7 mt-4">
                <div className="container mt-4">
                    <div className="wt-separator-two-part">
                        <div className="row wt-separator-two-part-row">
                            <div className="col-xl-6 col-lg-6 col-md-12 wt-separator-two-part-left">
                                {/* title="" START*/}
                                <div className="section-head left wt-small-separator-outer">
                                </div>
                                {/* title="" END*/}
                            </div>
                        </div>
                    </div>
                </div>
                <div className="twm-recommended-Jobs-mid-wrap">
                    <div className="twm-recommended-Jobs-mid">
                        <div className="container">
                            <div className="filter-carousal">
                                {/* Filter Menu */}
                                <div className="twm-jobs-filter">
                                    <ul className="btn-filter-wrap">
                                        <li className="btn-filter btn-active" data-filter="*" style={{ marginRight:"10px" ,fontWeight:"bold"}}>모두 보기 (<CountUp end={recommendedJobs.length} duration={3} />)</li>
                                        <li className="btn-filter" data-filter=".col-one" style={{marginRight:"10px" ,fontWeight:"bold"}}>문화</li>
                                        <li className="btn-filter" data-filter=".col-two" style={{marginRight:"10px" ,fontWeight:"bold"}}>생활</li>
                                        <li className="btn-filter" data-filter=".col-three" style={{marginRight:"10px" ,fontWeight:"bold"}}>일자리</li>
                                        <li className="btn-filter" data-filter=".col-five" style={{marginRight:"10px" ,fontWeight:"bold"}}>문의</li>
                                    </ul>
                                </div>
                                {/* Filter Menu */}
                                {/* IMAGE CAROUSEL START */}
                                <div className="section-content ">
                                    <div className="owl-carousel owl-carousel-filter mfp-gallery owl-btn-vertical-center">
                                        {/* COLUMNS ONE */}
                                        {recommendedJobs.map((job, index) => (
                                                <div className={`item col-${job.class}`} key={index}>
                                                    <div className="hpage-7-featured-block" style={{backgroundColor:"snow"}}>
                                                        <div className="inner-content">
                                                            <div className="top-content-wrap">
                                                                <div className="mid-content">
                                                                    <div className="company-logo" style={{ width:"100px"}}>
                                                                        <JobZImage src={job.imageSrc} alt="#" />
                                                                    </div>
                                                                </div>
                                                                <NavLink to={job.webSiteLink}  className="bottom-content">
                                                                    <h4 className="job-name-title">{job.title}</h4>
                                                                    <div className="job-payment">
                                                                        <span>{job.description}</span>
                                                                    </div>
                                                                </NavLink>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                    </div>
                                </div>
                            </div>
                            {/**상담 */}
                        </div>
                    </div>
                </div>
            </div>
            {/* Recommended Jobs SECTION END */}
        </>
    )
}

export default Home2Page;