import { publicUrlFor } from "../../globals/constants";
import { NavLink } from "react-router-dom";

function InnerPageBanner({_data}) {
    return (
        <>
            <div className="wt-bnr-inr overlay-wraper bg-center" style={{height:"150px", backgroundImage: `url(${publicUrlFor("images/banner/1.jpg")})` }}>
                <div className="overlay-main site-bg-white opacity-01" />
                <div className="container">
                </div>
            </div>
        </>
    )
}

export default InnerPageBanner;