function AllDemoFloatingMenu(props) {
    return (
        <>
            {/*<a href="#"
                id="all-demo-open"
                className="all-demos-view"
                style={{border:"1px solid black",borderTop:"0px"}}
                onClick={props.onClick}
            >도움이 되는 서비스</a>
            */}
        </>
    )
}

export default AllDemoFloatingMenu;