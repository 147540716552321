import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";
import Home2Page from "../home/index2";
import Home3Page from "../home/index3";

function CandidateDetail1Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "역사 문화 및 생활, 축제",
            title: "문화",
            image: "images/candidates/study1.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutText = [
            {
                title:"1) 한국 역사와 문화",
                img: "images/candidates/study1_1.png",
                discription:(
                    <>
                        <p>한국은 동북아시아에 위치한 반도 국가로, 유구한 역사와 전통을 자랑합니다. 한민족은 기원전 3천년경부터 이 땅에서 역사를 이어왔습니다. 고구려, 백제, 신라 세 나라가 있다가 나중에 고려로 통일되었어요. 그 후 조선 시대가 600년 이상 지속되면서 한국의 전통문화가 꽃을 피웠답니다.</p>
                        <p>우리나라에는 아름다운 문화유산이 정말 많아요. 서울 한복판에 있는 경복궁은 조선시대 왕궁으로 아름다운 건축물과 정원이 있죠. 또 불교 문화유산인 석굴암, 불국사, 해인사 등도 유명해요. 이런 문화유산들이 유네스코 세계문화유산으로 지정되어 있어요.</p>
                        <p>한국 사람들은 명절을 아주 중요하게 여겨요. 설날에는 가족들이 모여서 윷놀이를 하고 전통 음식인 떡국을 먹어요. 추석에는 보름달을 감상하며 조상에게 감사기도를 드려요.</p>
                        <p>한국에는 다양한 전통 음식도 있어요. 불고기, 비빔밥, 잡채 등 기본 밥과 반찬이 푸짐해요. 매운맛 매력이 큰 김치는 한국의 대표 반찬이에요. 한과, 식혜, 수정과 등 전통 디저트도 있죠.</p>
                        <p>재미있는 전통 민속놀이도 많답니다. 팽이치기, 제기차기, 널뛰기, 연날리기 등 다양한 활동들이 있어요. 지역별로 열리는 전통 축제에서 이런 민속놀이를 직접 체험해볼 수 있죠.</p>
                        <p>이렇게 한국은 오랜 역사와 찬란한 문화유산, 다채로운 전통문화를 가지고 있어요.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"2) 의식주 생활 방식",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: "의복",
                        item_discription: (
                            <>
                                <p>
                                한국에는 전통복식인 한복이 있습니다. 한복은 곡선미가 아름다운 옷으로 예로부터 허리를 조이지 않고 활동하기 편했습니다. 요즘도 명절이나 축제 때 한복을 입곤 합니다. 요즘 한국인들은 대부분 서양식 의상을 입고 생활합니다. 하지만 명절이나 혼례 때는 여전히 전통 한복을 곱게 차려입는 모습을 볼 수 있죠. 젊은 층 사이에서도 한복의 아름다움에 대한 관심이 높아지고 있습니다.
                                </p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "식생활",
                        item_discription: (
                            <>
                                <p>밥과 반찬을 함께 먹는 것이 특징입니다. 기본적인 밥상 차림은 예전과 비슷하지만, 서양 식기와 음식도 흔히 접할 수 있습니다. 또한 1인 가구 증가로 간편식, 배달음식 문화가 발달했죠.</p>
                                <p>하지만 김치, 국, 조기, 젓갈 등 기본 반찬은 여전히 중요합니다. 아침엔 토스트, 샌드위치 등 서구식도 많이 먹지만, 죽, 북편, 누룽지 등 전통식도 인기입니다.</p>
                                <p>식사 예절 역시 전통을 중시합니다. 연장자 앞에서 젓가락 사용법, 인사법 등 기본 예의범절을 지키는 것이 중요하죠.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "주거생활",
                        item_discription: (
                            <>
                                <p>전통적인 주거형태인 ‘한옥’은 마당과 건물이 조화를 이루며, 난방은 구들과 온돌을 활용했습니다.</p>
                                <p>서울 ‘북촌한옥마을’과 전주 ‘한옥마을’ 등 과거의 주거 형태가 유지되어 있는 곳으로 여행을 가보는 것도 새로운 즐거움을 줄 것입니다. 현대의 한국인은 대다수가 아파트나 빌라에 거주하며, 난방과 취사는 전기나 가스를 이용합니다.</p>
                                <p>하지만 전통 주거문화의 영향으로 거실과 방의 공간 분리, 온돌 문화 등이 남아있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            },
            {
                title:"3) 명절과 축제 문화",
                img: "images/candidates/study1_2.png",
                discription: (
                    <>
                    <p>한국에는 오래전부터 내려오는 뿌리 깊은 명절 문화가 있습니다. 대표적인 명절로는 설날, 추석, 단오 등이 있죠.</p>
                    <p>설날(구정)은 한국의 가장 큰 명절입니다. 설날 아침에는 가족이 모여 차례를 지내고, 세배를 드리며 인사를 나눕니다. 또한 윷놀이, 제기차기, 연날리기 등 다양한 세시풍속을 즐깁니다. 특별한 명절 음식으로는 덕용, 가래떡, 약식 등이 있습니다.</p>
                    <p>추석은 가족들이 모여 조상의 넋을 기리는 명절입니다. 보름달을 감상하며 차례를 지내고, 송편을 빚어 먹습니다. 추석 무렵에는 각 지역별로 다양한 민속놀이와 축제가 열립니다.</p>
                    <p>단오는 초복과 함께 무더운 여름을 식혀주는 명절로, 수릿날 차례와 그네뛰기, 씨름대회 등 행사가 있습니다. 창포물에 머리를 감는 등 행운을 기원하는 풍습도 전해 내려옵니다.</p>
                    <p>이외에도 정월 대보름, 동지 등 다양한 명절이 있습니다.</p>
                    <p>한국에는 지역별로 유명한 축제도 많이 있습니다. 대표적인 예로 안동국제탈춤페스티벌, 보령머드축제, 이천 도자기 축제 등이 있습니다. 이런 축제에서는 민속공연, 체험행사, 먹을거리 등 다채로운 프로그램을 즐길 수 있습니다.</p>
                    <p>이처럼 한국에는 오랜 역사와 의미가 담긴 명절과 축제 문화가 계승되고 있습니다. 이를 통해 한국의 전통과 정서를 엿볼 수 있습니다.</p>
                    </>
                ),
                Static_items:[null]
            },
        ]

        const workExperienceItems = [
            {
                title: "한국 소개",
                site:[
                    {
                        siteName : "위키피디아",
                        contactInfo: "https://en.wikipedia.org/wiki/South_Korea"
                    },
                ]
            },
            {
                title: "지역축제",
                site:[
                    {
                        siteName : "문화체육관광부",
                        contactInfo: "https://www.mcst.go.kr/kor/s_culture/festival/festivalList.jsp"
                    },
                ]
            },
        ];

        return (
            <>
                <div className="section-full p-b90 bg-white">
                <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {/*items*/}
                                            {aboutText.map((item, index) => (
                                                item && (
                                                    <div key={index}>
                                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{item.title}</h4>
                                                        {item.img && <JobZImage src={item.img} alt="#" />}
                                                        <div>
                                                            {item.discription}
                                                        </div>
                                                        <ul className="description-list-2">
                                                            {item.Static_items && item.Static_items.map((innerItem, innerIndex) => (
                                                                innerItem && (
                                                                    <li key={innerIndex}>
                                                                        <i className="feather-check" />
                                                                        <span style={{fontWeight:"bold"}}>{innerItem.item_title}</span>
                                                                        <div>
                                                                        {innerItem.img && <JobZImage src={innerItem.img} alt="#" />}
                                                                        </div>
                                                                        <div>
                                                                            {innerItem.item_discription}
                                                                        </div>
                                                                        <ul className="description-list-2">
                                                                            {innerItem.item_inner.map((subItem, subIndex) => (
                                                                                <li key={subIndex}>
                                                                                    {subItem}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            ))}
                                        {/*items*/}
                                    </div>
                                    
                                    <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 웹사이트</h4>
                                    <div className="twm-timing-list-wrap">
                                        {workExperienceItems.map((item, index) => (
                                        <div className="twm-timing-list" key={index}>
                                            <div className="twm-time-list-title">{item.title}</div>
                                            {item.site.map((siteItem, siteIndex) => (
                                                <div key={siteIndex}>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {siteItem.contactInfo && <NavLink to={siteItem.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">{siteItem.siteName} 바로가기</NavLink>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <Home2Page />
                            <Home3Page />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateDetail1Page;
