import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import SectionBlogsSidebar from "../../sections/blogs/sidebar/section-blogs-sidebar";

function BlogDetail7Page() {
    // 블로그 데이터 변수 정의
    const blogData = {
        title: "날씨",
        imageSrc: "images/blog/latest/bg7.png",
        description: [
            "한국의 날씨는 사계절이 뚜렷하고 다양한 매력을 가지고 있습니다. 봄에는 따뜻한 날씨와 아름다운 벚꽃이 피어나며, 여름에는 즐거운 해수욕과 시원한 아이스크림이 기다리고 있습니다.",
            "가을에는 황금빛 단풍과 맑은 날씨가 인상적이며, 겨울에는 하얀 눈과 따뜻한 차를 마시며 즐길 수 있는 겨울 스포츠가 많습니다.",
            "하지만 한국의 날씨는 가끔 극심한 기온 변화나 갑작스러운 비바람이 발생할 수 있으므로 주의가 필요합니다. 특히 여름에는 폭염이나 장마로 인한 홍수 등의 자연재해가 발생할 수 있으니, 적절한 대비가 필요합니다."
        ],
        aboutBusiness: [
            {
                title: "봄 (3월 ~ 5월)",
                content: "봄에는 따뜻한 날씨와 아름다운 벚꽃이 피어나는데, 산책이나 소풍 등을 즐길 수 있습니다. 하지만 갑작스러운 비가 내릴 수 있으니 우산을 챙기는 것이 좋습니다."
            },
            {
                title: "여름 (6월 ~ 8월)",
                content: "여름에는 즐거운 해수욕이 가능하고, 시원한 아이스크림을 즐길 수 있습니다. 하지만 폭염이나 장마로 인한 홍수 등의 위험이 있으니 주의가 필요합니다."
            },
            {
                title: "가을 (9월 ~ 11월)",
                content: "가을에는 황금빛 단풍과 맑은 날씨가 인상적입니다. 하지만 갑작스러운 기온 변화에 대비하여 따뜻한 옷을 준비하는 것이 좋습니다."
            },
            {
                title: "겨울 (12월 ~ 2월)",
                content: "겨울에는 하얀 눈과 따뜻한 차를 마시며 겨울 스포츠를 즐길 수 있습니다. 그러나 추위에 대비하여 충분한 보온과 방한이 필요합니다."
            }
        ],
        listItems: [
            "한국의 날씨는 사계절이 뚜렷하고 다양한 매력을 가지고 있습니다.",
            "날씨 변화에 따라 즐길 거리와 활동이 다양하니 계절별로 적절한 활동을 즐기면 좋습니다.",
            "갑작스러운 기후 변화에 대비하여 적절한 대비가 필요합니다."
        ]
    };
    
    const blockquoteData = [
        {
            author: "지원",
            quote: "봄에는 벚꽃 구경을 가볼까?"
        },
        {
            author: "성민",
            quote: "여름에는 해수욕장에 가서 시원한 바다를 즐기자!"
        },
        {
            author: "예은",
            quote: "가을에는 단풍 구경하러 산에 가야지."
        },
        {
            author: "지훈",
            quote: "겨울에는 눈싸움이나 스키 타러 가볼까?"
        }
    ];

    function CustomBlockQuote({ quote, author }) {
        return (
            <blockquote>
                <strong style={{fontWeight:"bold"}}>{author}</strong>
                <p><span>"</span>- {quote}</p>
            </blockquote>
        );
    }

    useEffect(() => {
        loadScript("js/custom.js");
    });

    return (
        <>
            <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    {/* BLOG SECTION START */}
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                {/* BLOG START */}
                                <div className="blog-post-single-outer">
                                    <div className="blog-post-single bg-white">
                                        <div className="wt-post-info">
                                            <div className="wt-post-media m-b30" style={{width: "100%", display:"flex", justifyContent:"center"}}>
                                                <JobZImage src={blogData.imageSrc} alt="" style={{width: "300px"}}/>
                                            </div>
                                            <div className="wt-post-title">
                                                <h3 className="post-title">{blogData.title}</h3>
                                            </div>
                                            <div className="wt-post-discription">
                                                {blogData.description.map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                                <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{blogData.aboutBusiness.title}</h4>
                                                {blogData.aboutBusiness.map((about, index) => (
                                                    <div key={index}>
                                                        <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{about.title}</h4>
                                                        <p>{about.content}</p>
                                                    </div>
                                                ))}
                                                <div className="wt-post-discription">
                                                    {blockquoteData.map((data, index) => (
                                                        <CustomBlockQuote key={index} quote={data.quote} author={data.author} />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="post-single-list">
                                                <ul className="description-list-2">
                                                    {blogData.listItems.map((item, index) => (
                                                        <li key={index}>
                                                            <i className="feather-check" />
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-navigation m-t30">
                                        <div className="post-nav-links" style={{ display: "block" }}>
                                            <NavLink to={publicUser.blog.GRID1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="post-nav-item nav-post-prev" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="nav-post-arrow" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />이전 페이지
                                                </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogDetail7Page;
