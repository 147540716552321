import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import SectionBlogsSidebar from "../../sections/blogs/sidebar/section-blogs-sidebar";

function BlogDetail1Page() {
    // 블로그 데이터 변수 정의
    const blogData = {
        title: "인사",
        imageSrc: "images/blog/latest/bg1.png",
        description: [
            "한국의 인사 문화는 상대를 존중하고 친절함을 표현하는 것이 중요합니다. 직장에서의 인사는 업무 능력 뿐만 아니라 인격과 교양을 나타내는 중요한 요소입니다. 새로운 직장에 입사한 신입사원들은 인사법을 배우고 적용하는 데에 어려움을 겪을 수 있습니다. 그러나 인사는 상대방을 존중하고 친절함을 전달하는 기본적인 예절입니다. 아침에는 활기찬 표정으로 명랑한 인사를 주고 받으며, 상사나 선배 앞에서는 정중하고 예의 바른 태도를 유지하는 것이 좋습니다.",
            "한국의 인사 문화에서는 수고의 인사가 일반적으로 행해집니다. 퇴근할 때에는 상사나 선배에게 '수고하셨습니다'라고 말하고, 아랫사람에게는 '수고하세요'라는 표현을 사용합니다. 또한, 먼저 퇴근할 때에는 상대방의 일이 아직 남아있는지 확인하고 이에 대한 양해를 구하는 것이 예의입니다.",
            "한국에서 인사는 비지니스 상황에서도 중요한 역할을 합니다. 처음 만났을 때는 정중하고 명랑한 목례를 통해 상대방을 환영하며, 다시 만날 때에는 밝은 표정과 함께 인사를 주고 받는 것이 좋습니다. 인사는 서로간의 존중과 신뢰를 쌓는 중요한 요소이며, 올바른 인사 문화는 직장 내외의 관계를 원활하게 유지하는 데에 큰 도움이 됩니다."
        ],
        aboutBusiness: [
            {
                title: "인사의 중요성",
                content: "한국에서는 인사가 비즈니스 환경에서도 중요한 역할을 합니다. 올바른 인사는 상대방과의 관계를 원활하게 유지하고 신뢰를 쌓는 데에 도움이 됩니다."
            },
            {
                title: "수고의 인사",
                content: "한국의 비즈니스 문화에서는 수고의 인사가 흔히 사용됩니다. 퇴근할 때에는 상대방의 노고를 인정하고 감사의 인사를 전하는 것이 중요합니다."
            },
            {
                title: "정중한 목례",
                content: "한국의 비즈니스 상황에서는 처음 만났을 때와 다시 만날 때에 목례를 통해 상대방을 환영하고 인사하는 것이 중요합니다. 목례는 서로간의 존중과 친밀감을 나타내는 중요한 요소입니다."
            }
        ],
        listItems: [
            "인사는 예절의 기본이며, 상대방을 존중하고 친절함을 전달하는 중요한 수단입니다.",
            "수고의 인사는 한국의 비즈니스 문화에서 흔히 사용되며, 상대방의 노고를 인정하고 감사의 인사를 전하는 것이 중요합니다.",
            "목례는 처음 만났을 때와 다시 만날 때에 상대방을 환영하고 인사하는 중요한 행동입니다."
        ]
    };
    
    const blockquoteData = [
        {
            author: "영희",
            quote: "오늘도 수고하셨어요!"
        },
        {
            author: "철수",
            quote: "수고하셨습니다. 내일 뵙겠습니다."
        }
    ];

    function CustomBlockQuote({ quote, author }) {
        return (
            <blockquote>
                <strong style={{fontWeight:"bold"}}>{author}</strong>
                <p><span>"</span>- {quote}</p>
            </blockquote>
        );
    }

    useEffect(() => {
        loadScript("js/custom.js");
    });

    return (
        <>
            <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    {/* BLOG SECTION START */}
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                {/* BLOG START */}
                                <div className="blog-post-single-outer">
                                    <div className="blog-post-single bg-white">
                                        <div className="wt-post-info">
                                            <div className="wt-post-media m-b30" style={{width: "100%", display:"flex", justifyContent:"center"}}>
                                                <JobZImage src={blogData.imageSrc} alt="" style={{width: "300px"}}/>
                                            </div>
                                            <div className="wt-post-title">
                                                <h3 className="post-title">{blogData.title}</h3>
                                            </div>
                                            <div className="wt-post-discription">
                                                {blogData.description.map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                                <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{blogData.aboutBusiness.title}</h4>
                                                {blogData.aboutBusiness.map((about, index) => (
                                                    <div key={index}>
                                                        <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{about.title}</h4>
                                                        <p>{about.content}</p>
                                                    </div>
                                                ))}
                                                <div className="wt-post-discription">
                                                    {blockquoteData.map((data, index) => (
                                                        <CustomBlockQuote key={index} quote={data.quote} author={data.author} />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="post-single-list">
                                                <ul className="description-list-2">
                                                    {blogData.listItems.map((item, index) => (
                                                        <li key={index}>
                                                            <i className="feather-check" />
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-navigation m-t30">
                                        <div className="post-nav-links" style={{ display: "block" }}>
                                            <NavLink to={publicUser.blog.GRID1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="post-nav-item nav-post-prev" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="nav-post-arrow" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />이전 페이지
                                                </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogDetail1Page;
