import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";

function CandidateDetail13Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "결혼",
            title: "한국인과 결혼하면 거주비자를 받을 수 있어요",
            image: "images/candidates/pic13.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutMeText = (
            <>
                <p>F-2 비자는 외국인이 한국인과 결혼하여 한국에서 거주하고 싶을 때 발급되는 비자 종류입니다. 한국인과의 결혼으로 인한 비자 발급 절차 및 관련 정보를 안내해 드리겠습니다.</p>
                
                <p>F-2 비자를 받기 위해서는 한국인과의 결혼을 증명하는 서류와 관련 절차를 준비해야 합니다. 또한, 결혼 관련 법률 및 규정을 준수해야 하며, 필요한 서류를 제출하여 신청해야 합니다.</p>
                
                <p>한국에서 한국인과의 결혼으로 F-2 비자를 받은 외국인은 한국에서 자유롭게 거주하고 근로할 수 있습니다. 그러나 비자 유효기간 및 갱신 절차 등을 주의하여야 합니다.</p>
            </>
        );
        
        const personalCharacteristics = [
            "결혼 관련 서류 및 절차 파악",
            "결혼 관련 법률 및 규정 이해",
            "비자 신청 및 갱신 절차 숙지",
        ];
        
        const workExperienceItems = [
            {
                title: null,
                position: null,
                contactInfo: null,
                description: null,
                cvUrl: null,
            },
        ];
        return (
            <>
                <div className="section-full p-b90 bg-white">
                    <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
    
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {aboutMeText}
                                            <div>
                                                <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 주의사항</h4>
                                                <ul className="description-list-2">
                                                    {personalCharacteristics.map((characteristic, index) => (
                                                        <li key={index}>
                                                            <i className="feather-check" />
                                                            {characteristic}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                        
                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 서비스 안내</h4>
                                        <div className="twm-timing-list-wrap">
                                            {workExperienceItems.map((item, index) => (
                                                <div className="twm-timing-list" key={index}>
                                                    <div className="twm-time-list-title">{item.title}</div>
                                                    <div className="twm-time-list-position">{item.position}</div>
                                                    <div className="twm-time-list-discription">
                                                        <p>{item.description}</p>
                                                    </div>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {item.contactInfo && <NavLink to={item.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">홈페이지 이동</NavLink>}
                                                        {item.cvUrl && <a href={item.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </div>
                                
                                <div className="post-navigation m-t30">
                                    <div className="post-nav-links"  style={{display:"block"}}>
                                        <NavLink to={publicUser.candidate.LIST} style={{display:"flex",justifyContent:"center", alignItems:"center"}}>
                                        <div className="post-nav-item nav-post-prev" style={{width: "100%",  display:"flex", justifyContent:"center", alignItems:"center"}}>
                                            <div className="nav-post-arrow" style={{ display:"flex", justifyContent:"center", alignItems:"center"}} >
                                                    <i className="fa fa-angle-left" style={{marginRight:"10px"}}/>이전 페이지
                                            </div>
                                        </div>
                                        </NavLink>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        )
    }

export default CandidateDetail13Page;
