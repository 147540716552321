import { publicUrlFor } from "../../../globals/constants";
import JobZImage from "../jobz-img";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../globals/route-names";

function Footer3() {
    return (
        <>
            <footer className="footer-dark" style={{ padding:"0px", backgroundImage: `url(${publicUrlFor("images/f-bg.png")})` }}>
                <div className="container">
                    {/* FOOTER BLOCKES START */}
                    <div className="footer-top">
                        <div className="row">
                            <div className="col-lg-3 col-md-3" style={{width:"100%"}}>
                                <div className="widget widget_about" style={{width: "100%", display:"flex", justifyContent:"space-around"}}>
                                    <div className="logo-footer clearfix">
                                        <NavLink to={publicUser.HOME1}><JobZImage id="skin_footer_dark_logo" src="images/logo-light.png" alt="" /></NavLink>
                                    </div>
                                    <ul className="ftr-list">
                                    <li><NavLink to={`https://workvisa.co.kr?utm_source=workzilla&utm_medium=footer&utm_campaign=menu`} target="_blank" rel="noopener noreferrer" style={{fontWeight:"bold",color:"white"}}>워크비자</NavLink></li>
                                        <li><p><span>이메일 :</span>workvisa@naver.com</p></li>
                                        <li><p><span>사업자등록번호 :</span>884-18-01907</p></li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>

        </>
    )
}

export default Footer3;