import React from "react";
import { NavLink } from "react-router-dom";
import JobZImgae from "../../../../common/jobz-img";
import { publicUser } from "../../../../../globals/route-names";
import SectionPagination from "../../sections/common/section-pagination";

function BlogGrid1Page() {

    const blogPosts = [
        {
            id: 1,
            author: "인사",
            detailLink: publicUser.blog.DETAIL1,
            title: "한국인과 인사하는 방법",
            imageSrc: "images/blog/latest/bg1.png",
        },{
            id: 2,
            author: "공공장소",
            detailLink: publicUser.blog.DETAIL2,
            title: "공공장소 알아보기",
            imageSrc: "images/blog/latest/bg2.png",
        },{
            id: 3,
            author: "식사",
            detailLink: publicUser.blog.DETAIL3,
            title: "한국인의 식사 문화",
            imageSrc: "images/blog/latest/bg3.png",
        },{
            id: 4,
            author: "술",
            detailLink: publicUser.blog.DETAIL4,
            title: "한국인의 술 문화",
            imageSrc: "images/blog/latest/bg4.png",
        },{
            id: 5,
            author: "행동",
            detailLink: publicUser.blog.DETAIL5,
            title: "선배 후배 위계질서",
            imageSrc: "images/blog/latest/bg5.png",
        },{
            id: 6,
            author: "연락",
            detailLink: publicUser.blog.DETAIL6,
            title: "문자나 전화하는 방법",
            imageSrc: "images/blog/latest/bg6.png",
        },{
            id: 7,
            author: "날씨",
            detailLink: publicUser.blog.DETAIL7,
            title: "날씨 정보 알아보기",
            imageSrc: "images/blog/latest/bg7.png",
        },{
            id: 8,
            author: "선물",
            detailLink: publicUser.blog.DETAIL8,
            title: "한국의 특별한 상품",
            imageSrc: "images/blog/latest/bg8.png",
        },{
            id: 9,
            author: "돈",
            detailLink: publicUser.blog.DETAIL9,
            title: "더치페이 또는 팁 문화",
            imageSrc: "images/blog/latest/bg9.png",
        }
    ];
    
    return (
        <div className="section-full p-t120 p-b90 site-bg-white">
            <div className="container">
                <div className="masonry-wrap row d-flex">
                    {/* 블로그 포스트 생성 */}
                    {blogPosts.map(post => (
                        <div key={post.id} className="masonry-item col-lg-4 col-md-12">
                            <NavLink to={post.detailLink}>
                                <div className="blog-post twm-blog-post-1-outer">
                                    <div className="wt-post-media" style={{display:"flex",justifyContent:"center"}}>
                                        <JobZImgae src={post.imageSrc} alt="" style={{width: "150px"}}/>
                                    </div>
                                    <div className="wt-post-info">
                                        <div className="wt-post-meta">
                                            <ul>
                                                <li className="post-date">{post.author}</li>
                                            </ul>
                                        </div>
                                        <div className="wt-post-title">
                                            <h4 className="post-title">
                                                {post.title}
                                            </h4>
                                        </div>
                                        <div className="wt-post-readmore site-button-link ">
                                            <div className="site-text-primary">더 알아보기</div>
                                        </div>
                                    </div>
                                </div>
                            </NavLink>
                        </div>
                    ))}
                </div>
                <SectionPagination />
            </div>
        </div>
    );
}

export default BlogGrid1Page;