import React, { useEffect, useState, useRef } from "react";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import { loadScript } from "../../../../../globals/constants";
import JobZImage from "../../../../common/jobz-img";
import SectionPagination from "../../sections/common/section-pagination";
import SectionJobsSidebar1 from "../../sections/jobs/sidebar/section-jobs-sidebar1";
import { publicUrlFor } from "../../../../../globals/constants";
import CountUp from "react-countup";

function CandidateListPage() {

    const selectRef = useRef(null); // select 요소에 대한 ref 생성

    useEffect(() => {
        loadScript("js/custom.js");
    }, []);

    const [selectedType, setSelectedType] = useState(""); // 상태 추가

    const candidates = [
        {
            typeNum:"생활",
            name: "역사 문화 및 생활, 축제",
            profession: "한국에서 거주할 수 있는 원룸을 찾아보세요",
            detail: publicUser.candidate.DETAIL1,
            image: "images/candidates/pic1.png"
        },
        {
            typeNum:"생활",
            name: "주거 환경 & 주거지 계약",
            profession: "한국에서 사용할 수 있는 휴대폰을 구매하세요",
            detail: publicUser.candidate.DETAIL2,
            image: "images/candidates/pic2.png"
        },
        {
            typeNum:"생활",
            name: "식생활 및 식료품 구매",
            profession: "병원비를 줄일 수 있는 방법을 배웁니다",
            detail: publicUser.candidate.DETAIL3,
            image: "images/candidates/pic3.png"
        },
        {
            typeNum:"생활",
            name: "의료 서비스 이용",
            profession: "자동차를 구매하고 싶은 사람을 위한 서비스",
            detail: publicUser.candidate.DETAIL4,
            image: "images/candidates/pic4.png"
        },
        {
            typeNum:"생활",
            name: "교통 및 생활 편의시설",
            profession: "범죄를 예방하고 합법적으로 체류하세요",
            detail: publicUser.candidate.DETAIL5,
            image: "images/candidates/pic5.png"
        },
        {
            typeNum:"생활",
            name: "유학 생활",
            profession: "돈을 벌 수 있는 일을 하고 여유로운 생활을 시작해요",
            detail: publicUser.candidate.DETAIL6,
            image: "images/candidates/pic6.png"
        },
        {
            typeNum:"생활",
            name: "안전 및 법률",
            profession: "한국에서 친구를 만드는 방법을 알아봅시다",
            detail: publicUser.candidate.DETAIL7,
            image: "images/candidates/pic7.png"
        },
        {
            typeNum:"생활",
            name: "휴대폰 사용 방법",
            profession: "한국어를 공부할 수 있는 좋은 방법을 배워요",
            detail: publicUser.candidate.DETAIL8,
            image: "images/candidates/pic8.png"
        },
        {
            typeNum:"업무",
            name: "아르바이트",
            profession: "대학교를 졸업하고 구직활동을 위한 비자를 만들어요",
            detail: publicUser.candidate.DETAIL9,
            image: "images/candidates/pic9.png"
        },
        {
            typeNum:"업무",
            name: "취업",
            profession: "전문직으로 취업하고 한국에서 일할 수 있어요",
            detail: publicUser.candidate.DETAIL10,
            image: "images/candidates/pic10.png"
        },
        {
            typeNum:"기타",
            name: "기타 학업, 생활, 행정, 취업 상담 창구",
            profession: "능력이 없어도 한국에서 일을 할 수 있어요",
            detail: publicUser.candidate.DETAIL11,
            image: "images/candidates/pic11.png"
        },
    ];

    const filteredCandidates = selectedType ? candidates.filter(candidate => candidate.typeNum === selectedType) : candidates;
    const typeOptions = [...new Set(candidates.map(candidate => candidate.typeNum))];

    useEffect(() => {
        // 선택이 변경될 때마다 페이지의 스크롤을 맨 위로 이동
        window.scrollTo({ top: 0, behavior: 'smooth' });
    }, [selectedType]);

    return (
        <div className="section-full p-t120 p-b90 site-bg-white">
            <div className="container">
                <div className="row">
                    <div className="col-lg-4 col-md-12 rightSidebar">
                        <div className="twm-advertisment" style={{ backgroundImage: `url(${publicUrlFor("images/add-bg.jpg")})` }}>
                            <div className="overlay" />
                            <h4 className="twm-title">필요한 서비스를 쉽게 찾아보세요</h4>
                            <p style={{fontSize:"20px"}}>
                                <CountUp end={filteredCandidates.length} duration={2} />
                                {" 개의 정보가 있습니다"}
                            </p>
                        </div>
                        <div className="product-filter-wrap d-flex justify-content-between align-items-center m-b30">
                            <form className="woocommerce-ordering twm-filter-select" method="get">
                                <span className="woocommerce-result-count">선택 필터</span>
                                <select
                                    className="wt-select-bar-2 selectpicker"
                                    data-live-search="true"
                                    data-bv-field="size"
                                    onChange={(e) => setSelectedType(e.target.value)} // 선택된 옵션 변경 시 상태 갱신
                                >
                                    <option value="">전체</option>
                                    {typeOptions.map((type, index) => (
                                        <option key={index} value={type}>{type}</option>
                                    ))}
                                </select>
                            </form>
                        </div>
                    </div>
                    <div className="col-lg-8 col-md-12">
                        <div className="twm-candidates-list-wrap">
                            <ul>
                                {filteredCandidates.map((candidate, index) => (
                                    <li key={index}>
                                        <NavLink to={candidate.detail } className="twm-job-title">
                                            <div className="twm-candidates-list-style1 mb-5">
                                                <div className="twm-media">
                                                    <div className="twm-media-pic">
                                                        <JobZImage src={candidate.image} alt="#" />
                                                    </div>
                                                    <div className="twm-candidates-tag"><span>{candidate.typeNum}</span></div>
                                                </div>
                                                <div className="twm-mid-content">
                                                        <h4>{candidate.name}</h4>
                                                        <p>{candidate.profession}</p>
                                                        <div className="twm-fot-content">
                                                            <div className="twm-left-info">
                                                            </div>
                                                            <div className="twm-right-btn">
                                                                <div className="site-text-primary twm-view-prifile">더 알아보기</div>
                                                            </div>
                                                        </div>
                                                </div>
                                            </div>
                                        </NavLink>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <SectionPagination />
                    </div>
                </div>
            </div>
        </div>
    );
}

export default CandidateListPage;