import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";
import Home2Page from "../home/index2";
import Home3Page from "../home/index3";

function CandidateDetail1Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "유학 생활",
            title: "생활",
            image: "images/candidates/study6.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutText = [
            {
                title:"1) 비자 및 체류자격 관리",
                img: null,
                discription:(
                    <>
                        <p>유학비자 또는 거주비자 등 적절한 체류자격을 유지해야 합니다. ( 학교성적, 출석률, 과제, 수업태도 등 )</p>
                        <p>체류기간 만료 전 반드시 체류기간 연장을 해야 합니다.</p>
                        <p>비자 만료일은 본인이 가장 잘 알고 있어야 하며, 이것은 항상 미리 준비하고, 확인해야 합니다.</p>
                        <p>아르바이트는 허용된 범위 내에서만 가능합니다. 비자의 종류에 따라 업종과 시간이 정해져 있으므로, 내용을 확실하게 숙지한 다음 아르바이트를 해야 합니다.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"2) 대학 규정 준수",
                img: null,
                discription:(
                    <>
                        <p>각 대학의 학칙과 규정을 숙지하고 준수해야 합니다.</p>
                        <p>출석, 성적 관리, 행동 수칙 등을 잘 지켜야 합니다.</p>
                        <p>대학 기숙사 생활 시에는 기숙사 규정을 지켜야 합니다.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"3) 한국 법규 준수",
                img: null,
                discription:(
                    <>
                        <p>한국의 자유롭지만 책임을 강조합니다. 그래서 법률을 준수해야 하며 법을 위반하면 처벌을 받을 수 있습니다.</p>
                        <p>음주, 폭력, 절도 등의 행위는 엄격히 금지됩니다.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"4) 기본 예의 지키기",
                img: null,
                discription:(
                    <>
                        <p>대중교통, 공공장소에서 기본 에티켓과 질서를 지켜야 합니다.</p>
                        <p>시끄럽거나 지나치게 과격한 행동은 자제해야 합니다.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"5) 문화 존중",
                img: null,
                discription:(
                    <>
                        <p>한국의 역사, 문화, 전통을 존중하는 자세가 필요합니다.</p>
                        <p>문화적 차이를 인정하고 개방적인 자세로 임해야 합니다.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"6) 안전 유의",
                img: null,
                discription:(
                    <>
                        <p>범죄 예방을 위해 밤길 등에 주의해야 합니다.</p>
                        <p>화재, 자연재해 등 비상사태 대비 매뉴얼을 숙지해야 합니다.</p>
                    </>
                ),
                Static_items:[null]
            },
        ]
        const workExperienceItems = [
            {
                title: "외국인 대상 온라인 민원서비스",
                site:[
                    {
                        siteName : "하이코리아",
                        contactInfo: "https://www.hikorea.go.kr"
                    },
                ]
            },
        ];

        return (
            <>
                <div className="section-full p-b90 bg-white">
                <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {/*items*/}
                                            {aboutText.map((item, index) => (
                                                item && (
                                                    <div key={index}>
                                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{item.title}</h4>
                                                        {item.img && <JobZImage src={item.img} alt="#" />}
                                                        <div>
                                                            {item.discription}
                                                        </div>
                                                        <ul className="description-list-2">
                                                            {item.Static_items && item.Static_items.map((innerItem, innerIndex) => (
                                                                innerItem && (
                                                                    <li key={innerIndex}>
                                                                        <i className="feather-check" />
                                                                        <span style={{fontWeight:"bold"}}>{innerItem.item_title}</span>
                                                                        <div>
                                                                        {innerItem.img && <JobZImage src={innerItem.img} alt="#" />}
                                                                        </div>
                                                                        <div>
                                                                            {innerItem.item_discription}
                                                                        </div>
                                                                        <ul className="description-list-2">
                                                                            {innerItem.item_inner.map((subItem, subIndex) => (
                                                                                <li key={subIndex}>
                                                                                    {subItem}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            ))}
                                        {/*items*/}
                                    </div>
                                    
                                    <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 웹사이트</h4>
                                    <div className="twm-timing-list-wrap">
                                        {workExperienceItems.map((item, index) => (
                                        <div className="twm-timing-list" key={index}>
                                            <div className="twm-time-list-title">{item.title}</div>
                                            {item.site.map((siteItem, siteIndex) => (
                                                <div key={siteIndex}>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {siteItem.contactInfo && <NavLink to={siteItem.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">{siteItem.siteName} 바로가기</NavLink>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <Home2Page />
                            <Home3Page />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateDetail1Page;
