import { useState } from 'react';

function FaqPage() {
const faqData = [
    {
        id: 1,
        category: "문화",
        items: [
            {
                id: 1,
                question: "한국에서 어떻게 크리스마스를 보내나요?",
                answer: "한국에서 크리스마스는 주로 연인이나 친구들과 함께 보내는 날이며, 크리스마스 트리를 꾸미고, 선물을 주고받는 등의 활동이 일어납니다."
            },
            {
                id: 2,
                question: "한국의 설날은 어떻게 지내나요?",
                answer: "설날은 가족들이 모여서 전통 음식을 먹고, 친척을 만나는 등의 행사를 가집니다. 또한, 조상을 기리는 차례도 있습니다."
            },
            {
                id: 3,
                question: "한국에서 웨딩 문화는 어떤가요?",
                answer: "한국의 웨딩 문화는 주로 가족 중심으로 진행되며, 전통적인 의복을 입고 예식을 올리는 경우가 많습니다. 또한, 결혼식 후에는 식사와 함께 손님들과의 인사도 중요합니다."
            },
            {
                id: 4,
                question: "한국의 명절 중 하나인 추석은 어떻게 보내나요?",
                answer: "추석은 가족들이 모여서 차례를 드리고 함께 음식을 먹는 등의 행사를 가집니다. 또한, 고향에 있는 친척들을 만나러 가는 것도 일반적입니다."
            },
            {
                id: 5,
                question: "한국의 명절 중 하나인 추석은 어떻게 보내나요?",
                answer: "추석은 가족들이 모여서 차례를 드리고 함께 음식을 먹는 등의 행사를 가집니다. 또한, 고향에 있는 친척들을 만나러 가는 것도 일반적입니다."
            },
        ]
    },
    {
        id: 2,
        category: "음식",
        items: [
            {
                id: 1,
                question: "한국에서 가장 인기 있는 음식은 무엇인가요?",
                answer: "한국에서 가장 인기 있는 음식 중 일부는 김치, 불고기, 라면 등이 있습니다."
            },
            {
                id: 2,
                question: "한국의 음식은 매워요?",
                answer: "일반적으로 한국 음식은 매운 맛을 좋아하는 경우가 많지만, 매운 정도는 요리나 지역에 따라 다를 수 있습니다."
            },
            {
                id: 3,
                question: "한국에서 먹는 떡국은 어떤 음식인가요?",
                answer: "떡국은 한국의 떡을 이용하여 만든 국물 요리로, 주로 설날에 먹습니다. 시원한 국물과 쫄깃한 떡이 특징입니다."
            },
            {
                id: 4,
                question: "한국에서 유명한 술은 무엇인가요?",
                answer: "한국에서 대표적으로 유명한 술은 소주와 막걸리가 있습니다. 각 지역마다 특색 있는 전통주도 많이 즐겨 마십니다."
            },
            {
                id: 5,
                question: "한국에서 대표적인 김치 요리는 무엇인가요?",
                answer: "한국에서 대표적인 김치 요리에는 김치찌개, 김치볶음밥, 김치전 등이 있습니다. 김치는 한국 음식 문화에서 중요한 역할을 합니다."
            },
        ]
    },
    {
        id: 3,
        category: "여행",
        items: [
            {
                id: 1,
                question: "한국에서 여행하기 좋은 시기는 언제인가요?",
                answer: "한국은 봄과 가을이 가장 기후가 좋고 경치가 아름다운 시기입니다. 또한, 가을에는 단풍 구경이 인기 있습니다."
            },
            {
                id: 2,
                question: "한국에서 꼭 가봐야 할 관광지는 어디인가요?",
                answer: "한국에는 경복궁, 인사동, 홍대 등 많은 관광지가 있지만, 각자의 취향에 따라 다를 수 있습니다."
            },
            {
                id: 3,
                question: "한국의 자연 경관이 아름다운 곳은 어디인가요?",
                answer: "한국에는 설악산, 지리산, 제주도 등 자연 경관이 아름다운 곳이 많습니다. 특히 제주도의 해변은 많은 관광객들이 찾는 명소 중 하나입니다."
            },
            {
                id: 4,
                question: "한국에서 문화 체험을 할 수 있는 장소는 어디인가요?",
                answer: "한국의 전통 의상을 입고 한복체험을 할 수 있는 장소나 전통 놀이를 체험할 수 있는 문화 체험마을이 있습니다. 인사동이나 종묘도 한국의 전통 문화를 체험할 수 있는 곳 중 하나입니다."
            },
            {
                id: 5,
                question: "한국에서의 교통 수단은 무엇이 있나요?",
                answer: "한국에서는 지하철, 버스, 택시 등 다양한 교통 수단을 이용할 수 있습니다. 특히 서울과 대도시 지역에서는 대중교통이 발달되어 있습니다."
            },
        ]
    },
];

    const [activeTab, setActiveTab] = useState(faqData[0].category);

    const handleTabChange = (category) => {
        setActiveTab(category);
    };

    return (
        <>
            <div className="section-full p-t120 p-b90 site-bg-white" style={{minHeight: "calc(100vh - 375px)"}}>
                <div className="container">
                    <div className="section-content">
                        <div className="twm-tabs-style-1 center">
                            <ul className="nav nav-tabs" id="myTab" role="tablist">
                                {faqData.map(category => (
                                    <li className="nav-item" key={category.id}>
                                        <button className={`nav-link ${activeTab === category.category ? 'active' : ''}`} 
                                                onClick={() => handleTabChange(category.category)}>
                                            {category.category}
                                        </button>
                                    </li>
                                ))}
                            </ul>
                            <div className="tab-content" id="myTabContent">
                                {faqData.map(category => (
                                    <div className={`tab-pane fade ${activeTab === category.category ? 'show active' : ''}`} 
                                         id={category.category} role="tabpanel" key={category.id}>
                                        <div className="tw-faq-section">
                                            <div className="accordion tw-faq" id={`${category.category}-faq-accordion`}>
                                                {/* FAQ 항목 생성 */}
                                                {category.items.map(item => (
                                                    <div key={item.id} className="accordion-item">
                                                        <button className="accordion-button collapsed" type="button" 
                                                                data-bs-toggle="collapse" 
                                                                data-bs-target={`#${category.category}-FAQ${item.id}`} 
                                                                aria-expanded="false">
                                                            {item.question}
                                                        </button>
                                                        <div id={`${category.category}-FAQ${item.id}`} 
                                                             className="accordion-collapse collapse" 
                                                             data-bs-parent={`#${category.category}-faq-accordion`}>
                                                            <div className="accordion-body">
                                                                {item.answer}
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default FaqPage;
