import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";
import { loadScript } from "../../../../../globals/constants";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import SectionBlogsSidebar from "../../sections/blogs/sidebar/section-blogs-sidebar";

function BlogDetail3Page() {
    // 블로그 데이터 변수 정의
    const blogData = {
        title: "식사",
        imageSrc: "images/blog/latest/bg3.png",
        description: [
            "한국의 식사 문화는 가족이나 친구들과 함께 식사하는 것이 중요한 가치로 여겨집니다. 한국인들은 식사를 통해 서로의 관계를 더욱 굳건하게 만들고 소통하는 시간으로 여깁니다. 또한, 한국의 식사는 다양한 반찬을 함께 나누는 것이 특징입니다.",
            "한국의 식사 예절은 상대방을 배려하고 존중하는 마음가짐으로 이루어집니다. 가족이나 노인들이 식사할 때는 어르신들에게 먼저 음식을 내어주는 것이 예의입니다. 또한, 식사 중에는 큰 소리를 내거나 과도한 말을 하지 않도록 주의해야 합니다.",
            "특히 한국의 식사에서는 술자리가 자주 이루어집니다. 술자리에서는 잔을 받은 손으로 자신의 잔을 들이거나 음료를 따르는 것이 예절입니다. 또한, 나이 많은 사람이나 상사에게는 손을 받쳐주는 것이 예의입니다. 술자리에서는 상대방과의 대화를 즐기고 배려하는 마음가짐으로 즐겁고 화기애애한 분위기를 조성하는 것이 중요합니다."
        ],
        aboutBusiness: [
            {
                title: "가족 식사의 중요성",
                content: "한국에서는 가족이 함께 식사하는 것이 중요한 가치로 여겨집니다. 가족 식사는 서로의 관계를 굳건하게 만들고 소통하는 시간으로 여겨집니다."
            },
            {
                title: "음식 나누기와 배려",
                content: "한국의 식사 문화에서는 다양한 반찬을 함께 나누는 것이 중요합니다. 또한, 상대방을 배려하고 존중하는 마음가짐으로 식사하는 것이 예절입니다."
            },
            {
                title: "술자리 예절",
                content: "한국의 술자리에서는 상대방과의 대화를 즐기고 배려하는 마음가짐으로 즐겁고 화기애애한 분위기를 조성하는 것이 중요합니다. 술자리에서는 상대방에게 예의를 지키며 대접하는 것이 중요합니다."
            }
        ],
        listItems: [
            "한국의 식사 문화는 가족이나 친구들과 함께하는 것이 중요한 가치로 여겨집니다.",
            "식사 중에는 상대방을 배려하고 존중하는 마음가짐으로 행동해야 합니다.",
            "술자리에서는 상대방과의 대화를 즐기고 배려하는 마음가짐으로 화기애애한 분위기를 조성해야 합니다."
        ]
    };
    
    const blockquoteData = [
        {
            author: "지훈",
            quote: "오늘도 맛있는 식사를 함께해요!"
        },
        {
            author: "수빈",
            quote: "상대방과의 대화를 즐기면서 배려하는 것이 중요해."
        }
    ];

    function CustomBlockQuote({ quote, author }) {
        return (
            <blockquote>
                <strong style={{fontWeight:"bold"}}>{author}</strong>
                <p><span>"</span>- {quote}</p>
            </blockquote>
        );
    }

    useEffect(() => {
        loadScript("js/custom.js");
    });

    return (
        <>
            <div className="section-full  p-t120 p-b90 bg-white">
                <div className="container">
                    {/* BLOG SECTION START */}
                    <div className="section-content">
                        <div className="row d-flex justify-content-center">
                            <div className="col-lg-8 col-md-12">
                                {/* BLOG START */}
                                <div className="blog-post-single-outer">
                                    <div className="blog-post-single bg-white">
                                        <div className="wt-post-info">
                                            <div className="wt-post-media m-b30" style={{width: "100%", display:"flex", justifyContent:"center"}}>
                                                <JobZImage src={blogData.imageSrc} alt="" style={{width: "300px"}}/>
                                            </div>
                                            <div className="wt-post-title">
                                                <h3 className="post-title">{blogData.title}</h3>
                                            </div>
                                            <div className="wt-post-discription">
                                                {blogData.description.map((paragraph, index) => (
                                                    <p key={index}>{paragraph}</p>
                                                ))}
                                                <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{blogData.aboutBusiness.title}</h4>
                                                {blogData.aboutBusiness.map((about, index) => (
                                                    <div key={index}>
                                                        <h4 className="twm-blog-s-title mt-5" style={{fontWeight:"bold"}}>{about.title}</h4>
                                                        <p>{about.content}</p>
                                                    </div>
                                                ))}
                                                <div className="wt-post-discription">
                                                    {blockquoteData.map((data, index) => (
                                                        <CustomBlockQuote key={index} quote={data.quote} author={data.author} />
                                                    ))}
                                                </div>
                                            </div>
                                            <div className="post-single-list">
                                                <ul className="description-list-2">
                                                    {blogData.listItems.map((item, index) => (
                                                        <li key={index}>
                                                            <i className="feather-check" />
                                                            {item}
                                                        </li>
                                                    ))}
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="post-navigation m-t30">
                                        <div className="post-nav-links" style={{ display: "block" }}>
                                            <NavLink to={publicUser.blog.GRID1} style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <div className="post-nav-item nav-post-prev" style={{ width: "100%", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                    <div className="nav-post-arrow" style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                        <i className="fa fa-angle-left" style={{ marginRight: "10px" }} />이전 페이지
                                                </div>
                                                </div>
                                            </NavLink>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

export default BlogDetail3Page;
