import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";
import Home2Page from "../home/index2";
import Home3Page from "../home/index3";

function CandidateDetail1Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "아르바이트",
            title: "일자리",
            image: "images/candidates/study9.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutText = [
            {
                title:"1) 아르바이트 허가 및 제한 사항",
                img: null,
                discription: (
                    <>
                        <p>유학생은 출입국관리사무소에서 아르바이트 허가를 받아야 합니다.</p>
                        <p>허가 없이 일하면 불법으로 간주됩니다.</p>
                        <p>정해진 업종에서 일을 해야 합니다. ( ex 유흥업소, 제조업, 건설근로자는 불가하며, 상담 후 진행 필요)</p>
                    </>
                ),
                Static_items:[
                    {
                        img: null,
                        item_title: "아르바이트 시간",
                        item_discription: (
                            <>
                                <p>D-4 비자 : 입학 후 6개월 이후부터 가능, 일주일에 10시간 허용 (TOPIK 2급 이상은 20시간 허용)</p>
                                <p>D-2 비자(전문대, 4년대 1~2학년) : 즉시 가능, 일주일에 10시간 허용 (TOPIK 3급 이상은 25시간 허용, 주말/방학 중 무제한)</p>
                                <p>D-2 비자(4년대 3~4학년) : 즉시 가능, 일주일에 10시간 허용 (TOPIK 4급 이상은 25시간 허용)</p>
                                <p>D-2 비자(석사과정) : 즉시 가능, 일주일에 15시간 허용 (TOPIK 4급 이상은 30시간 허용)</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: "images/candidates/study9_1.png",
                        item_title: null,
                        item_discription: null,
                        item_inner: [null],
                    },
                ]
            },
            {
                title:"2) 임금 체불 및 부당 대우 대처 방안",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: "고용노동부에 진정 및 상담",
                        item_discription: (
                            <>
                                <p>고용노동부 근로개선과나 근로감독관에게 임금체불이나 부당대우 사실을 알리고 진정할 수 있습니다.</p>
                                <p>근로감독관이 사업체를 방문하여 실태를 조사하고 시정명령을 내릴 수 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "권리구제 소송",
                        item_discription: (
                            <>
                                <p>임금체불 등 권리 침해에 대해 민사소송을 제기할 수 있습니다.</p>
                                <p>변호사를 선임하거나 대한법률구조공단의 도움을 받을 수 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "상담기관 이용",
                        item_discription: (
                            <>
                                <p>각 대학의 유학생 상담센터나 외국인력지원센터에 도움을 요청합니다.</p>
                                <p>한국이주노동운동본부 등 외국인노동자 지원단체에 상담을 요청할 수 있습니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "취업 알선 기관 활용",
                        item_discription: (
                            <>
                                <p>취업에 어려움이 있다면 공공기관의 취업지원프로그램을 활용하는 것도 방법입니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "증거 확보",
                        item_discription: (
                            <>
                                <p>임금체불, 부당대우의 증거(녹음, 문자 등)를 확보하는 것이 중요합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            },
        ]
        const workExperienceItems = [
            {
                title: "아르바이트 취업 사이트",
                site:[
                    {
                        siteName : "워크비자",
                        contactInfo: "https://www.workvisa.co.kr"
                    },
                ]
            },
            {
                title: "임금체불 상담",
                site:[
                    {
                        siteName : "추후 지원",
                        contactInfo: "https://www.startkorea.com"
                    },
                ]
            },
        ];

        return (
            <>
                <div className="section-full p-b90 bg-white">
                <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {/*items*/}
                                            {aboutText.map((item, index) => (
                                                item && (
                                                    <div key={index}>
                                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{item.title}</h4>
                                                        {item.img && <JobZImage src={item.img} alt="#" />}
                                                        <div>
                                                            {item.discription}
                                                        </div>
                                                        <ul className="description-list-2">
                                                            {item.Static_items && item.Static_items.map((innerItem, innerIndex) => (
                                                                innerItem && (
                                                                    <li key={innerIndex}>
                                                                        <i className="feather-check" />
                                                                        <span style={{fontWeight:"bold"}}>{innerItem.item_title}</span>
                                                                        <div>
                                                                        {innerItem.img && <JobZImage src={innerItem.img} alt="#" />}
                                                                        </div>
                                                                        <div>
                                                                            {innerItem.item_discription}
                                                                        </div>
                                                                        <ul className="description-list-2">
                                                                            {innerItem.item_inner.map((subItem, subIndex) => (
                                                                                <li key={subIndex}>
                                                                                    {subItem}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            ))}
                                        {/*items*/}
                                    </div>
                                    
                                    <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 웹사이트</h4>
                                    <div className="twm-timing-list-wrap">
                                        {workExperienceItems.map((item, index) => (
                                        <div className="twm-timing-list" key={index}>
                                            <div className="twm-time-list-title">{item.title}</div>
                                            {item.site.map((siteItem, siteIndex) => (
                                                <div key={siteIndex}>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {siteItem.contactInfo && <NavLink to={siteItem.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">{siteItem.siteName} 바로가기</NavLink>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <Home2Page />
                            <Home3Page />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateDetail1Page;
