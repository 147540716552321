import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";
import Home2Page from "../home/index2";
import Home3Page from "../home/index3";

function CandidateDetail1Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "주거 환경 & 주거지 계약",
            title: "생활",
            image: "images/candidates/study2.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutText = [
            {
                title:"1) 기숙사 생활 안내",
                img: null,
                discription:(
                    <>
                        <p>기숙사는 소중한 학생 여러분들이 학업에 집중하고 편안하게 지낼 수 있는 곳입니다. 여러분의 방에서는 편안한 침대, 공부를 할 수 있는 책상, 그리고 옷을 넣을 수 있는 옷장이 준비되어 있어요.</p>
                        <p>또한 공용 시설에는 주방, 세탁실, 화장실 등이 있어요.</p>
                        <p>여러분들이 기숙사 생활을 즐겁게 할 수 있도록 몇 가지 규칙이 있어요.</p>
                        <p>예를 들어, 밤에는 잠을 자는 시간이 정해져 있고, 방문자를 제한하는 규칙도 있어요.</p>
                        <p>소음을 조심해야 하고, 공용 시설을 깨끗하게 사용하는 것이 중요해요.</p>
                        <p>또한, 기숙사는 안전하고 편리한 환경을 제공하기 위해 노력하고 있어요.</p>
                        <p>CCTV 카메라가 설치되어 있고, 비상 연락처도 알려져 있어요. 함께 있는 친구들과 서로 존중하고 도와주며, 즐거운 시간을 보내는 것이 중요해요.</p>
                        <p>함께 살면서 서로를 배려하고 즐겁게 생활할 수 있도록 노력해 주세요.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"주거 옵션(자취, 하숙 등)",
                img: "images/candidates/study2_1.png",
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: null,
                        item_discription: null,
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "아파트(apartment)",
                        item_discription: null,
                        item_inner: [
                            (
                                <>
                                    <p>한국에서 가장 흔하게 볼 수 있는 주거 형식 중 하나입니다.</p>
                                    <p>아파트는 고층 건물로, 각 층마다 여러 개의 주택이 있습니다.</p>
                                    <p>대부분의 아파트는 경비원이 상주하고 있으며, 공용 시설로는 주차장, 경비실, 엘리베이터, 산책로, 놀이터 등을 제공합니다.</p>
                                </>
                            )
                        ],
                    },
                    {
                        img: null,
                        item_title: "빌라(Villa)",
                        item_discription: null,
                        item_inner: [
                            (
                                <>
                                    <p>한국에서 가장 흔하게 볼 수 있는 주거 형식 중 하나입니다.</p>
                                    <p>아파트는 고층 건물로, 각 층마다 여러 개의 주택이 있습니다.</p>
                                    <p>대부분의 아파트는 경비원이 상주하고 있으며, 공용 시설로는 주차장, 경비실, 엘리베이터, 산책로, 놀이터 등을 제공합니다.</p>
                                </>
                            )
                        ],
                    },
                    {
                        img: null,
                        item_title: "원룸(One room)",
                        item_discription: null,
                        item_inner: [
                            (
                                <>
                                    <p>반적으로 한 방에 침실, 거실, 부엌, 욕실이 모두 함께 있는 형태의 주택을 말합니다.</p>
                                    <p>싱글 또는 커플이 주로 거주하며, 학생들이나 직장인들이 많이 선택하는 주거 형태입니다.</p>
                                    <p>( 방이 2개면 투룸(Two room), 3개면 쓰리룸(Three room) )</p>
                                </>
                            )
                        ],
                    },
                    {
                        img: null,
                        item_title: "오피스텔(officetel, office+hotel)",
                        item_discription: null,
                        item_inner: [
                            (
                                <>
                                    <p>아파트와 원룸의 중간 형태로, 작은 공간에 생활 및 업무 시설이 함께 있는 건물을 의미합니다.</p>
                                    <p>주로 싱글 또는 커플이 거주하며, 공간 활용이 효율적인 주거 형태입니다.</p>
                                </>
                            )
                        ],
                    },
                    {
                        img: "images/candidates/study2_2.png",
                        item_title: "단독주택",
                        item_discription: null,
                        item_inner: [
                            (
                                <>
                                    <p>독립적인 주택으로, 한 가정이 전용으로 사용하는 건물을 말합니다.</p>
                                    <p>주로 가족 단위로 거주하며, 주택의 크기와 스타일은 다양합니다.</p>
                                </>
                            )
                        ],
                    },
                ]
            },
            {
                title:"3) 주거 계약 및 임대차 절차",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: "주거 계약 형태",
                        item_discription: (
                            <>
                            <p>월세 : 계약자가 집주인에게 월단위로 집세를 내는 계약방식</p>
                            <p>전세 : 임대료를 한 번에 납부하고, 나중에 돌려받는 계약방식</p>
                            </>
                        ),
                        item_inner: [
                            (
                                <>
                                    <p>- 임대차 절차</p>
                                    <p>① 중개사나 부동산 웹사이트를 통해 원하는 주택을 찾습니다.</p>
                                    <p>② 관심 있는 주택을 선택하면 중개사와 계약서를 작성하거나, 직접 집주인과 계약을 체결합니다.</p>
                                    <p>③ 계약서 작성 시 임대 기간, 보증금 및 월세, 계약 조건 등을 명확히 기재해야 합니다.</p>
                                    <p>④ 계약서 작성 후 보증금 및 월세를 집주인에게 지불하고, 계약을 완료합니다.</p>
                                    <p>⑤ 계약 진행 시 전기, 수도, 가스 등을 확인해야 합니다.</p>
                                    <p>⑥ 일반적으로 이사 전에는 청소가 완료되어 있으며, 계약 종료 때에도 깨끗한 상태가 유지 되어야 합니다.</p>
                                    <p></p>
                                    <p>한국에서의 주거 계약은 일반적으로 중개사나 부동산 업체를 통해 이루어지며, 보증금과 월세를 함께 지불하는 형태가 흔합니다.</p>
                                    <p>계약서 작성 시에는 모든 조건을 명확히 기재하고, 주택 내부의 상태와 임대 조건을 주의 깊게 검토하는 것이 중요합니다.</p>
                                </>
                            )
                        ],
                    },
                ]
            }
        ]
        const workExperienceItems = [
            {
                title: "부동산 사이트",
                site:[
                    {
                        siteName : "다방",
                        contactInfo: "https://www.dabangapp.com"
                    },
                    {
                        siteName : "직방",
                        contactInfo: "https://www.www.zigbang.com"
                    },
                ]
            },
        ];

        return (
            <>
                <div className="section-full p-b90 bg-white">
                <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {/*items*/}
                                            {aboutText.map((item, index) => (
                                                item && (
                                                    <div key={index}>
                                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{item.title}</h4>
                                                        {item.img && <JobZImage src={item.img} alt="#" />}
                                                        <div>
                                                            {item.discription}
                                                        </div>
                                                        <ul className="description-list-2">
                                                            {item.Static_items && item.Static_items.map((innerItem, innerIndex) => (
                                                                innerItem && (
                                                                    <li key={innerIndex}>
                                                                        <i className="feather-check" />
                                                                        <span style={{fontWeight:"bold"}}>{innerItem.item_title}</span>
                                                                        <div>
                                                                        {innerItem.img && <JobZImage src={innerItem.img} alt="#" />}
                                                                        </div>
                                                                        <div>
                                                                            {innerItem.item_discription}
                                                                        </div>
                                                                        <ul className="description-list-2">
                                                                            {innerItem.item_inner.map((subItem, subIndex) => (
                                                                                <li key={subIndex}>
                                                                                    {subItem}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            ))}
                                        {/*items*/}
                                    </div>
                                    
                                    <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 웹사이트</h4>
                                    <div className="twm-timing-list-wrap">
                                    {workExperienceItems.map((item, index) => (
                                        <div className="twm-timing-list" key={index}>
                                            <div className="twm-time-list-title">{item.title}</div>
                                            {item.site.map((siteItem, siteIndex) => (
                                                <div key={siteIndex}>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {siteItem.contactInfo && <NavLink to={siteItem.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">{siteItem.siteName} 바로가기</NavLink>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <Home2Page />
                            <Home3Page />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateDetail1Page;
