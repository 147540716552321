import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";
import Home2Page from "../home/index2";
import Home3Page from "../home/index3";

function CandidateDetail1Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "의료 서비스 이용",
            title: "생활",
            image: "images/candidates/study4.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutText = [
            {
                title:"1) 의료 시설",
                img: "images/candidates/study4_1.png",
                discription:(
                    <>
                        <p>한국에는 고급 의료 시설이 많이 있어서 다양한 의료 서비스를 받을 수 있습니다.</p>
                        <p>대형 병원부터 의원, 치과, 한방병원까지 다양한 의료 시설이 있으며, 대부분의 의료 시설에서는 최신의 의료 기술과 장비를 갖추고 있습니다.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"2) 의료 질",
                img: null,
                discription:(
                    <>
                        <p>한국의료 서비스는 전문적이고 고품질인 것으로 유명합니다. 의사와 간호사 등 의료진들은 전문적으로 훈련되어 있으며, 의료 기술의 최신 동향을 따라가고 있습니다.</p>
                        <p>또한, 의료 서비스의 효율성과 안전성을 높이기 위해 정부는 엄격한 표준과 규제를 시행하고 있습니다.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"3) 보험 시스템",
                img: null,
                discription:(
                    <>
                        <p>한국에는 의료 보험 시스템이 잘 발달되어 있어서 건강 보험에 가입한 경우 의료 서비스를 저렴하게 이용할 수 있습니다.</p>
                        <p>대부분의 한국인들은 국민건강보험에 가입되어 있으며, 외국인 유학생도 일정 조건을 충족하면 보험 가입이 가능합니다.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"4) 외국인을 위한 서비스",
                img: null,
                discription:(
                    <>
                        <p>한국에는 외국인을 위한 의료 서비스도 제공되고 있습니다.</p>
                        <p>다국어로 의료 서비스를 제공하는 의료 시설도 있으며, 외국인 환자를 위한 전문적인 서비스를 제공하는 병원도 있습니다.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"5) 응급 의료",
                img: null,
                discription:(
                    <>
                        <p>한국에는 응급 의료 서비스가 잘 발달되어 있어서 응급 상황이 발생했을 때 신속하고 전문적인 의료 서비스를 받을 수 있습니다.</p>
                        <p>응급실은 24시간 운영되며, 응급환자는 최우선적으로 치료를 받을 수 있습니다.</p>
                    </>
                ),
                Static_items:[null]
            },
            {
                title:"6) 약국",
                img: null,
                discription:(
                    <>
                        <p>병원에서 처방받은 처방전으로 약국에서 약품을 구매할 수 있다.</p>
                        <p>약국에서는 처방전 없이도 간단한 의약품을 살 수 있습니다.</p>
                    </>
                ),
                Static_items:[
                    {
                        img: null,
                        item_title: null,
                        item_discription: null,
                        item_inner: [
                            (
                                <>
                                    <p>응급 의료 요청 : 119, 112</p>
                                </>
                            )
                        ],
                    },
                ]
            }
        ]
        const workExperienceItems = [
            {
                title: "보험가입 문의",
                site:[
                    {
                        siteName : "추후 지원",
                        contactInfo: "https://startkorea.com"
                    },
                ]
            },
        ];

        return (
            <>
                <div className="section-full p-b90 bg-white">
                <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {/*items*/}
                                            {aboutText.map((item, index) => (
                                                item && (
                                                    <div key={index}>
                                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{item.title}</h4>
                                                        {item.img && <JobZImage src={item.img} alt="#" />}
                                                        <div>
                                                            {item.discription}
                                                        </div>
                                                        <ul className="description-list-2">
                                                            {item.Static_items && item.Static_items.map((innerItem, innerIndex) => (
                                                                innerItem && (
                                                                    <li key={innerIndex}>
                                                                        <i className="feather-check" />
                                                                        <span style={{fontWeight:"bold"}}>{innerItem.item_title}</span>
                                                                        <div>
                                                                        {innerItem.img && <JobZImage src={innerItem.img} alt="#" />}
                                                                        </div>
                                                                        <div>
                                                                            {innerItem.item_discription}
                                                                        </div>
                                                                        <ul className="description-list-2">
                                                                            {innerItem.item_inner.map((subItem, subIndex) => (
                                                                                <li key={subIndex}>
                                                                                    {subItem}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            ))}
                                        {/*items*/}
                                    </div>
                                    
                                    <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 웹사이트</h4>
                                    <div className="twm-timing-list-wrap">
                                        {workExperienceItems.map((item, index) => (
                                        <div className="twm-timing-list" key={index}>
                                            <div className="twm-time-list-title">{item.title}</div>
                                            {item.site.map((siteItem, siteIndex) => (
                                                <div key={siteIndex}>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {siteItem.contactInfo && <NavLink to={siteItem.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">{siteItem.siteName} 바로가기</NavLink>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <Home2Page />
                            <Home3Page />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateDetail1Page;
