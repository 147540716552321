import SectionCandidateShortIntro2 from "../../sections/candidates/detail2/section-can-short-intro2";
import { useEffect } from "react";
import { loadScript } from "../../../../../globals/constants";
import { NavLink } from "react-router-dom";
import { publicUser } from "../../../../../globals/route-names";
import JobZImage from "../../../../common/jobz-img";
import { publicUrlFor } from "../../../../../globals/constants";
import Home2Page from "../home/index2";
import Home3Page from "../home/index3";

function CandidateDetail1Page() {
    useEffect(()=>{
        loadScript("js/custom.js")
    })
    
    const candidates = {
            name: "식생활 및 식료품 구매",
            title: "생활",
            image: "images/candidates/study3.png",
            contactInfo: null,
            cvUrl: null
        }

        const aboutText = [
            {
                title:"1) 식문화 및 식습관",
                img: null,
                discription: null,
                Static_items:[
                    {
                        img: null,
                        item_title: "식당",
                        item_discription: (
                            <>
                                <p>한국에는 다양한 종류의 음식점이 있어서 외식을 즐기기에 좋은 곳입니다.</p>
                                <p>한식, 중식, 일식, 서양식 등 다양한 종류의 음식점이 있으며, 거의 모든 지역에서 쉽게 찾을 수 있습니다. 음식점에서는 메뉴가 한국어로 표시되어 있을 수 있습니다.</p>
                                <p>주문 시에는 '메뉴판을 보고 주문하고 싶어요'라고 말하면 됩니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "식료품 구매",
                        item_discription: (
                            <>
                                <p>한국에는 대형 슈퍼마켓부터 전통시장까지 다양한 유형의 식료품 구매처가 있습니다.</p>
                                <p>대형 슈퍼마켓에서는 식료품뿐만 아니라 생활용품, 화장품 등을 구매할 수 있습니다. 대표적인 대형 슈퍼마켓으로는 이마트, 홈플러스, 롯데마트 등이 있습니다. 전통시장에서는 신선한 채소, 과일, 생선, 해산물 등을 저렴하게 구매할 수 있습니다.</p>
                                <p>슈퍼마켓이나 전통시장에서는 대부분의 제품에 가격표가 붙어 있으므로, 가격을 확인하고 필요한 물품을 선택하면 됩니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: null,
                        item_title: "24시 편의점",
                        item_discription: (
                            <>
                                <p>24시간 편의점이 많이 있어서 언제든지 필요한 물품을 구매할 수 있습니다.</p>
                                <p>24시 편의점은 매우 편리하며 다양한 상품을 판매합니다. 주로 다음과 같은 물품을 구매할 수 있습니다.</p>
                                <p>컵라면, 즉석음식, 김밥, 샌드위치, 햄버거, 생활용품, 음료 및 주류, 문구용품, 기타 서비스(ATM서비스, 택배서비스 등)</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                    {
                        img: "images/candidates/study3_1.png",
                        item_title: "주의사항",
                        item_discription: (
                            <>
                                <p>한국의 식당이나 식료품 점포에서는 현금뿐만 아니라 신용카드나 체크카드를 사용하여 결제할 수 있습니다.</p>
                                <p>또한, 식당에서는 음식을 주문할 때 일반적으로 음식세트를 함께 제공하는 경우가 많으므로, 음식을 주문할 때 원하지 않는 경우에는 해당 사항을 직원에게 말씀해야 합니다.</p>
                            </>
                        ),
                        item_inner: [null],
                    },
                ]
            }
        ]
        const workExperienceItems = [
            {
                title: "온라인 쇼핑 사이트",
                site:[
                    {
                        siteName : "쿠팡",
                        contactInfo: "https://www.coupang.com"
                    },
                    {
                        siteName : "11번가",
                        contactInfo: "https://www.11st.co.kr"
                    },
                ]
            },
            {
                title: "중고거래 사이트",
                site:[
                    {
                        siteName : "당근마켓",
                        contactInfo: "https://www.daangn.com"
                    },
                    {
                        siteName : "번개장터",
                        contactInfo: "https://www.bunjang.co.kr"
                    },
                ]
            },
        ];

        return (
            <>
                <div className="section-full p-b90 bg-white">
                <div className="twm-candi-self-wrap-2 overlay-wraper" style={{ backgroundImage: `url(${publicUrlFor("images/candidates/candidate-bg2.jpg")})` }}>
                        <div className="overlay-main site-bg-primary opacity-01" />
                        <div className="container">
                        <div className="twm-candi-self-info-2">
                                <div className="twm-candi-self-top">
                                    <div className="twm-media">
                                        <JobZImage src={candidates.image} alt="#" />
                                    </div>
                                    <div className="twm-mid-content">
                                        <h4 className="twm-job-title">{candidates.name}</h4>
                                        <p>{candidates.title}</p>
                                    </div>
                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
                                        {candidates.cvUrl && <a href={candidates.cvUrl} className="site-button secondry m-1" target="_blank" rel="noopener noreferrer">파일 다운로드</a>}
                                    </div>
                                </div>
                        </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="section-content">
                            <div className="row d-flex justify-content-center">
                                <div className="col-lg-9 col-md-12">
                                    <div className="cabdidate-de-info">
                                        <div>
                                            <h4 className="twm-s-title m-t0" style={{fontWeight:"bold"}}>{candidates.name} 설명</h4>
                                            {/*items*/}
                                            {aboutText.map((item, index) => (
                                                item && (
                                                    <div key={index}>
                                                        <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{item.title}</h4>
                                                        {item.img && <JobZImage src={item.img} alt="#" />}
                                                        <div>
                                                            {item.discription}
                                                        </div>
                                                        <ul className="description-list-2">
                                                            {item.Static_items && item.Static_items.map((innerItem, innerIndex) => (
                                                                innerItem && (
                                                                    <li key={innerIndex}>
                                                                        <i className="feather-check" />
                                                                        <span style={{fontWeight:"bold"}}>{innerItem.item_title}</span>
                                                                        <div>
                                                                        {innerItem.img && <JobZImage src={innerItem.img} alt="#" />}
                                                                        </div>
                                                                        <div>
                                                                            {innerItem.item_discription}
                                                                        </div>
                                                                        <ul className="description-list-2">
                                                                            {innerItem.item_inner.map((subItem, subIndex) => (
                                                                                <li key={subIndex}>
                                                                                    {subItem}
                                                                                </li>
                                                                            ))}
                                                                        </ul>
                                                                    </li>
                                                                )
                                                            ))}
                                                        </ul>
                                                    </div>
                                                )
                                            ))}
                                        {/*items*/}
                                    </div>
                                    
                                    <h4 className="twm-s-title mt-5" style={{fontWeight:"bold"}}>{candidates.name} 웹사이트</h4>
                                    <div className="twm-timing-list-wrap">
                                        {workExperienceItems.map((item, index) => (
                                        <div className="twm-timing-list" key={index}>
                                            <div className="twm-time-list-title">{item.title}</div>
                                            {item.site.map((siteItem, siteIndex) => (
                                                <div key={siteIndex}>
                                                    <div className="twm-candi-self-bottom" style={{display:"flex",justifyContent:"flex-start",alignItems:"center"}}>
                                                        {siteItem.contactInfo && <NavLink to={siteItem.contactInfo} className="site-button m-1" target="_blank" rel="noopener noreferrer">{siteItem.siteName} 바로가기</NavLink>}
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    ))}
                                    </div>
                                </div>
                            </div>
                            <Home2Page />
                            <Home3Page />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default CandidateDetail1Page;
